import React, { JSX, useEffect, useState } from "react";
import styled from "styled-components";
import { Widget } from "./AnalyticsWidget";

const Stat = styled.div`
  //@property --num {
  //  syntax: "<integer>";
  //  initial-value: 0;
  //  inherits: false;
  //}

  transition: --num 3s;
  //counter-reset: num var("--num");
  //&::after {
  //  content: counter(num);
  //}
`;

export const NumberWidget = ({ label, value, bgImage }: { label: string; value: number; bgImage?: JSX.Element }) => {
  const [currentValue, setCurrentValue] = useState(0);

  console.debug(`numer value: ${value}`);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // @ts-ignore
  return (
    <Widget>
      {bgImage}
      {/*<Stat className="stat" style={{ "--num": currentValue }} />*/}
      <Stat className="stat">{currentValue}</Stat>
      <span>{label}</span>
    </Widget>
  );
};
