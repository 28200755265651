import getInvitation from "../../lib/requests/getInvitation";
import getUser from "../../lib/requests/getUser";
import getSettings from "../../lib/requests/getGlobalSettings";

export const initialRequests = async (hashedInvitationID: string) => {
  const controller = new AbortController();

  try {
    const [invitation, currentUser, globalSettings] = await Promise.all([
      getInvitation(hashedInvitationID, controller.signal),
      getUser(controller.signal),
      getSettings(controller.signal),
    ]);

    invitation.localDate = new Date();

    return { invitation, currentUser, globalSettings };
  } catch (e) {
    controller.abort();
    throw e;
  }
};
