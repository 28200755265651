import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { getFlowTimeString } from "./FlowItem";

interface Props {
  flow: LobbyInvitation;
}

export const FlowItemGroupSize: FC<Props> = ({ flow }) => {
  const flowDurationString = getFlowTimeString(flow);
  const numGroupsString = flow.numGroups
    ? flow.numGroups == 1
      ? `${flow.numGroups} group`
      : `${flow.numGroups} groups`
    : "groups";

  const minGroupSize = flow.minGroupSize || flow.min;
  const maxGroupSize = flow.maxGroupSize || flow.max;

  return (
    <div
      className="group-size"
      style={{
        fontSize: "14px",
        fontWeight: 400,
        color: "#4d4a4a",
        position: "relative",
        bottom: "-4px",
      }}
    >
      {flow.isGather ? (
        <FormattedMessage
          description="Flows List text for creating a group with all particpants"
          id="flows_list.groups_gather_all"
          defaultMessage="Gather all"
        />
      ) : minGroupSize === maxGroupSize ? (
        <FormattedMessage
          description="Flows List text for creating rooms with a set minimum number of participants"
          id="flows_list.min_groups"
          defaultMessage="{duration} min / {numGroups} of {min}"
          values={{ duration: flowDurationString, min: minGroupSize, numGroups: numGroupsString }}
        />
      ) : (
        <FormattedMessage
          description="Flows List text for creating rooms in a range of min to max participants with set preference"
          id="flows_list.min_max_groups"
          defaultMessage="{duration} min / {numGroups} of {min} - {max} (prefer: {preferred})"
          values={{
            duration: flowDurationString,
            min: minGroupSize,
            max: maxGroupSize,
            preferred: flow.preferred,
            numGroups: numGroupsString,
          }}
        />
      )}
    </div>
  );
};
