import React, { FC, useEffect, useRef, useState } from "react";
import { NumberWidget } from "./NumberWidget";
import styled from "styled-components";
import { BarsWidget } from "./BarsWidget";
import WordCloud from "./WordCloud";
import { store } from "../../../core/store";
import getAnalytics from "../../../lib/requests/getAnalytics";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  z-index: 2;
  width: 100%;
`;

const Heading = styled.h1`
  font-size: 50px;
  margin: 0;
  padding: 0;
`;

const Header = styled.div`
  margin-bottom: 10px;
`;

const Body = styled.div`
  display: flex;
  height: 100%;
`;

const WordcloudWrapper = styled.div`
  background: linear-gradient(180deg, #2d4055 0%, #0f2439 100%);
  border-radius: 10px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
`;

const WordcloudInner = styled.div`
  background: linear-gradient(180deg, #1c2530 0%, #071626 100%);
  border-radius: 8px;
  height: 100%;
  width: 100%;
  flex: 1;
`;

const SideWidgets = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;

  margin-left: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const Widget = styled.div`
  background: linear-gradient(180deg, #2d4055 0%, #0f2439 100%);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  flex: 1;

  img {
    position: absolute;
    z-index: 1;

    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .stat {
    z-index: 2;
    font-size: 80px;
    background: linear-gradient(rgb(255, 141, 36), rgb(255, 97, 46));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 800;
    line-height: 85px;
    font-style: normal;
    margin-bottom: 10px;
  }

  span {
    font-family: Helvetica;
    color: #ff8d24;
  }
`;

export interface WordCloudWord {
  text: string;
  count: number;
}

export interface AnalyticsResponse {
  words: WordCloudWord[];
  energyBefore: number;
  energyAfter: number;
  sessions: number;
  people: number;
  heat: number;
  countries: string[];
}

const alphabeticalSort = (a: WordCloudWord, b: WordCloudWord) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

export const AnalyticsWidget: FC = () => {
  const wordCloudContainer = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [data, setData] = useState<AnalyticsResponse>({
    words: [],
    energyBefore: 0,
    energyAfter: 0,
    sessions: 0,
    people: 0,
    countries: [],
    heat: 0,
  });
  const showHeat = true;

  const analyticsUrl = store.use.externalContentUrl()!;
  const mapUrl = `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/assets/map.png`;
  // Set up polling
  useEffect(() => {
    getAnalytics(analyticsUrl).then((analyticsResponse) => {
      setData(analyticsResponse);
    });
  }, [analyticsUrl]);

  // Track word cloud container size
  useEffect(() => {
    const func = () => {
      if (wordCloudContainer.current) {
        size.width = wordCloudContainer.current.offsetWidth;
        size.height = wordCloudContainer.current.offsetHeight;

        setSize({ ...size });
      }
    };

    window.addEventListener("resize", func);

    func();

    return () => {
      window.removeEventListener("resize", func);
    };
  }, [wordCloudContainer.current]);

  return (
    <Wrapper>
      {/*<Header>*/}
      {/*  <Heading>Live Connections</Heading>*/}
      {/*</Header>*/}

      <Body>
        <WordcloudWrapper>
          <WordcloudInner ref={wordCloudContainer}>
            <WordCloud
              words={data.words.sort(alphabeticalSort)}
              width={Math.max(1, size.width - 20)}
              height={Math.max(1, size.height - 40)}
            />
          </WordcloudInner>
        </WordcloudWrapper>

        <SideWidgets>
          {showHeat ? (
            <NumberWidget label="Heat Generated" value={data.heat || 0} key="heat" />
          ) : (
            <>
              <NumberWidget label="People Connected" value={data.people || 0} key="people" />
              <NumberWidget
                label="Countries"
                value={data.countries.length}
                key="countries"
                bgImage={<img src={mapUrl} alt="A map of the world as a barely visible background element" />}
              />
            </>
          )}

          <BarsWidget
            label="Energy Levels"
            before={data.energyBefore && !isNaN(data.energyBefore) ? data.energyBefore : 1}
            after={data.energyAfter && !isNaN(data.energyAfter) ? data.energyAfter : 1}
          />
        </SideWidgets>
      </Body>
    </Wrapper>
  );
};
