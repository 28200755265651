function hasRole(user: { authorizations: Authorization[] }, ...roles: Authorization[]) {
  return roles.some((role) => user.authorizations.includes(role));
}

export type Permissions =
  | "editCustomPrompts"
  | "updateInvitationSettings"
  | "manageBreakouts"
  | "manageOwnMuteState"
  | "advanceAudio"
  | "muteOthers"
  | "sendToRoom"
  | "controlSteps"
  | "manageParticipants" // this is only for facilitators and has no optional permission for regular users
  | "record";

export function can(
  permission: Permissions,
  user: Pick<UserResponse, "authorizations" | "id">,
  invitation: Pick<
    InvitationResponse,
    | "restrictFacilitatorActions"
    | "restrictUserActions"
    | "muteAll"
    | "controllingInqUserId"
    | "disableCustomPrompts"
    | "facilitators"
  >,
) {
  // Facilitators, superusers and admins can do anything
  if (hasRole(user, "facilitator", "admin", "super_user")) return true;

  switch (permission) {
    // Facilitator actions
    case "muteOthers":
      return !invitation.restrictFacilitatorActions;

    case "manageBreakouts":
      return !invitation.restrictFacilitatorActions;

    case "record":
      return !invitation.restrictFacilitatorActions;

    case "sendToRoom":
      return !invitation.restrictFacilitatorActions;

    case "updateInvitationSettings":
      return !invitation.restrictFacilitatorActions;

    // Other actions
    case "manageOwnMuteState":
      return !invitation.muteAll;

    case "advanceAudio":
      return !invitation.restrictUserActions;

    case "controlSteps":
      return user.id === invitation.controllingInqUserId;

    case "editCustomPrompts":
      return !invitation.disableCustomPrompts;

    default:
      return false;
  }
}
