import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Overlay, Card, CardHeader, CardFooter, CardBody } from "../Modal/ModalStyle";
import { store, useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import styled from "styled-components";
import { doAction } from "../../../core/SessionChannel/doAction";
import { useDaily } from "@daily-co/daily-react";
import { Leave } from "../../../assets/icons/Icons";

const ExitCard = styled(Card)`
  top: 40px;
  left: unset;
  right: unset;
  right: 6px;
  padding: 0px;
  padding-bottom: 3px;
  width: 250px;
  transform: translate(0%, 0%);
  z-index: 1000;

  ${CardBody} {
    padding: 0px;
  }

  ${CardFooter} {
    padding: 0px;
  }

  ${Button} {
    border-radius: 4px;
    margin: 0px;
    font-size: 18px;
    text-align: left !important;
    justify-content: space-between;
    display: flex;

    &:hover {
      transform: scale(1);
      background-color: #eee;
    }

    &.secondary {
      background-color: transparent;

      box-shadow: none;
      border-bottom: 1px solid #ddd;
      color: #000;
      width: 100%;
      z-index: 100;
    }
  }
`;

export const ConfirmLeaveModal: FC<{ inRoom: boolean }> = ({ inRoom }) => {
  const callObject = useDaily();
  const core = store.use.core();

  const handleCloseModal = () => {
    modalClear("ConfirmLeave");
  };

  return (
    <>
      <ExitCard>
        <CardHeader></CardHeader>
        <CardBody>
          {inRoom ? (
            <Button
              className="secondary small"
              onClick={() => {
                doAction(core, "end_session", -1, -1);
                modalClear("ConfirmLeave");
              }}
            >
              <FormattedMessage
                description="Button text to return to the lobby"
                id="confirm_leave_modal.return_to_lobby"
                defaultMessage="Return to Lobby"
              />
            </Button>
          ) : null}

          <Button
            className="secondary small"
            onClick={() => {
              useStore.setState({ mode: "end" });
              callObject?.leave();
              core.subscription?.unsubscribe();
              modalClear("ConfirmLeave");
            }}
          >
            <FormattedMessage
              description="Button text to leave the call"
              id="confirm_leave_modal.leave_call"
              defaultMessage="Leave Call"
            />
            <Leave fill="black" />
          </Button>
        </CardBody>
      </ExitCard>
      <Overlay onClick={handleCloseModal} blur={true} />
    </>
  );
};
