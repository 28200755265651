import React from "react";
import { Microphone, MicrophoneSlash } from "../../../assets/icons/Icons";
import MenuButton from "./MenuButton";
import { useAudioTrack, useDaily, useLocalParticipant } from "@daily-co/daily-react";
import { useIntl } from "react-intl";
import toast from "react-simple-toasts";

export const MicButton = () => {
  const callObject = useDaily();

  const intl = useIntl();

  const localParticipant = useLocalParticipant();

  const localAudio = useAudioTrack(localParticipant?.session_id || "");
  const micOn = !localAudio.isOff && localAudio.state !== "blocked" && localAudio.state !== "interrupted";

  const isMicDisabled = !micOn;

  function toggleMic() {
    toast(`Microphone ${isMicDisabled ? "unmuted" : "muted"}`, 2000);
    callObject?.setLocalAudio(isMicDisabled);
  }

  return (
    <MenuButton
      buttonIcon={isMicDisabled ? <MicrophoneSlash fill="white" width={"21px"} /> : <Microphone fill="white" />}
      onClick={toggleMic}
      style={{
        minWidth: "67px", // Reserve space for the longest text (Unmute) to prevent the button from resizing
      }}
    >
      {isMicDisabled
        ? intl.formatMessage({
            description: "Mic button text to unmute mic",
            id: "mic_button.unmute",
            defaultMessage: "Unmute",
          })
        : intl.formatMessage({
            description: "Mic button text to mute mic",
            id: "mic_button.mute",
            defaultMessage: "Mute",
          })}
    </MenuButton>
  );
};
