import { InterfaceRulesResponse } from "../helpers/warmspaceLogic";
import { Locales } from "../core/intl";
import { AudioFiles } from "../lib/narrationAudioLoader";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import { classifyDevice } from "../hooks/classifyDevice";
import CableMessage, { BreakoutRoomMessage, BreakoutStatus, RoomClosingType } from "../types/CableMessage";
import { Modals } from "../components/UI/Modal/Modals";
import { Core } from "./core";
import { createSelectors } from "../lib/createSelectors";

// Using the 'combine' way of creating a store so that the type
// of the store gets inferred automatically.
// So no need to maintain a type for the store.
export const useStore = create(
  combine(
    {
      // Initial requests
      currentUser: {} as UserResponse,
      invitation: {} as InvitationResponse,
      core: {} as Core,

      // UI State
      iframeVisible: false as boolean,
      inPerson: false as boolean,
      locale: "en" as Locales,
      mode: "prejoin" as Mode,
      sessionConsumerIsConnected: false as boolean,
      interfaceRules: { allowChat: false, allowModeSwitching: false } as InterfaceRulesResponse,
      timeOnStep: 0 as number,
      stepTiming: 0 as number,
      deviceClassification: {} as ReturnType<typeof classifyDevice>,
      showDropParticipantModal: -1 as number, // -1 means don't show it, otherwise it's the warmspaceID of the participant to drop
      showRenameParticipantModal: -1 as number, // -1 means don't show it, otherwise it's the warmspaceID of the participant to rename
      showFlowDescriptionModal: "" as string, // "" means don't show it, otherwise it's the flowHashID
      showModals: [] as Modals[],
      callJoined: false as boolean,

      // Lobby state
      autoJoin: false as boolean,

      // Add partner
      personalCode: undefined as number | undefined,
      showAddPartner: true as boolean,

      // Room closing
      sessionEndDate: undefined as Date | undefined,
      sessionClosingType: undefined as RoomClosingType | undefined,

      // skipBroadcastRef is a Audio Progress 'hack' for coordinating
      // which audio state changes should or should not broadcast to
      // other participants. (TODO: extract business logic out of AudioProgress
      // component and into Core and see if that simplifies things.)
      skipBroadcastRef: { current: false as boolean },

      // Music
      musicPlaying: false as boolean,
      musicVolume: 0.2,

      // VideoGrid
      showSelf: false as boolean,

      // Participants
      participants: {} as Participants,
      allParticipants: {} as Participants,
      participantsLastActiveDates: {} as ParticipantsLastActiveDates,
      participantSorting: [] as number[],
      recentHeat: {} as Heat,
      rooms: [] as BreakoutRoomMessage[],
      excludedParticipantIds: [] as number[],
      raisedHands: [] as number[],
      isBreakoutRoom: false as boolean,

      // Feature Permissions
      allowScreenshare: false as boolean,
      muteAll: false as boolean,
      restrictUserActions: false as boolean,

      // Recording & Transcription
      recording: false as boolean,
      transcribing: false as boolean,
      recordingLoading: false as boolean,
      closedCaptionsEnabled: false as boolean,

      // Narration Audio
      currentAudioObject: undefined as HTMLAudioElement | undefined,
      narrating: false as boolean,
      narrationAudio: {} as AudioFiles,
      narrationVolume: 0.3,

      // Chat
      chatMessages: [] as ChatMessage[],
      showChat: false as boolean,

      // Breakout
      showMatchingConfig: false as boolean,
      selectedFlow: null as LobbyInvitation | null,
      selectedFlowConfigPage: null as string | null,
      selectedRoomsView: "ongoing" as BreakoutStatus | undefined,
      selectedRoom: null as "allRooms" | string | null,
      selectedParticipant: null as Participant | null,
      currentFlowConfig: null as LobbyInvitation | null,

      // Analytics/External content to show
      showExternalContent: false as boolean,
      externalContentUrl: null as string | null,

      // updateMessage content
      audioProgress: NaN as number,
      currentStep: {} as AnyStep,
      lastStep: false as boolean,
      roleData: {} as RoleData,
      stepData: {} as StepIndexData,

      // stepContent is derived from currentStep and calculated in determineContent
      stepContent: undefined as StepContent | undefined,

      // For managing presences during transitions between flows
      transitioning: false as boolean,
      unprocessedPresencesMessages: [] as CableMessage[],

      // Heat Toasts per user
      heatToasts: {} as {
        [userID: number]: number;
      },
    },
    (set) => ({
      addChatMessage: (message: ChatMessage) => set((state) => ({ chatMessages: [message, ...state.chatMessages] })),

      updateParticipant: (participantID: number, participant: Partial<Participant>) => {
        set((state) => {
          const participants = { ...state.participants };
          participants[participantID] = Object.assign({}, participants[participantID], participant);
          return { participants };
        });
      },
    }),
  ),
);

export const store = createSelectors(useStore);
