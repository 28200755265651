import { useStore } from "../../../core/store";

export type Modals =
  | "AddParticipantToRoom"
  | "BreakoutModal"
  | "ConfirmBreakout"
  | "ConfirmCloseBreakout"
  | "ConfirmLeave"
  | "ConfirmReturnRoom"
  | "DropParticipant"
  | "ManageParticipants"
  | "MatchingConfig"
  | "MoreMenu"
  | "QrCode"
  | "RecordingMenu"
  | "ReportPartner"
  | "RoomsConfig"
  | "Settings";

export const modalClearAll = () => {
  useStore.setState({ showModals: [] });
};

export const modalClear = (modalType: Modals) => {
  useStore.setState((state) => ({ showModals: state.showModals.filter((m) => m != modalType) }));
};

export const modalToggle = (modalType: Modals) => {
  useStore.setState((state) => ({ showModals: state.showModals.includes(modalType) ? [] : [modalType] }));
};

export const modalShow = (modalType: Modals, clearOtherModals: boolean = true) => {
  useStore.setState((state) => {
    if (clearOtherModals) return { showModals: [modalType] };
    else {
      const newShowModals = [...state.showModals];
      newShowModals.push(modalType);
      return { showModals: newShowModals };
    }
  });
};
