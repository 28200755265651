import styled from "styled-components";
import React, { FC, useEffect } from "react";
import FlipNumbers from "react-flip-numbers";
import { Close, Flame } from "../../../assets/icons/Icons";
import { store } from "../../../core/store";
import { Core } from "../../../core/core";

const Wrapper = styled.button`
  border: none;
  font-weight: bold;
  display: flex;

  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  background-color: rgb(40, 40, 40);

  &:hover {
    background-color: rgb(50, 50, 50);
  }

  border-top: 1px solid #444;

  box-sizing: border-box;
  color: #ff8c1a;

  > svg {
    position: relative;
    top: -1px !important;
  }

  transition: width 0.5s;

  cursor: pointer;

  .glow {
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
    pointer-events: none;

    background: radial-gradient(
      circle at center 165%,
      rgba(40, 40, 40, 1) 56%,
      rgba(255, 140, 33, 1) 60%,
      rgba(40, 40, 40, 0) 77%,
      rgba(40, 40, 40, 0) 100%
    );

    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 6px;

    &.animate {
      animation: glow 1.5s ease-in-out;
    }
  }

  @keyframes glow {
    0% {
      background-position: center 5px;
    }
    50% {
      background-position: center -1px;
    }
    100% {
      background-position: center 7px;
    }
  }
`;

const HeatPopover = styled.div`
  position: absolute;
  background-color: #1d1d1d;
  inset: unset;
  top: 42px;
  left: 7px;

  border: 0px;
  font-size: 19px;
  font-weight: 900;
  border-radius: 9px;
  border-top: 1px solid #333;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  background-color: rgba(40, 40, 40, 0.9);
  backdrop-filter: blur(10px);
  width: 290px;
  padding: 12px 0px;
  color: #fff4f4;
  text-align: left;

  padding-bottom: 0px;
  overflow: hidden;

  .popoverGlow {
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    pointer-events: none;

    background-size: 100%;
    background: radial-gradient(
        circle at center 165%,
        rgba(40, 40, 40, 1) 56%,
        rgba(255, 140, 33, 1) 60%,
        rgba(40, 40, 40, 0) 77%,
        rgba(40, 40, 40, 0) 100%
      )
      no-repeat center 65px;
  }

  .content {
    z-index: 1;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .title {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    button.close {
      background: none;
      border: none;
      position: relative;
      left: 10px;
    }
  }

  .subtitle {
    font-size: 14px;
    padding: 0px 15px;
    color: #c8b2b2;
    font-weight: 300;
  }

  .participantList {
    margin-top: 10px;

    overflow-y: auto;
    padding: 0px 15px;
    padding-bottom: 20px;
    max-height: 400px;

    scrollbar-color: #444 #222;

    /* WebKit and Chromiums */

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #222;
    }

    &::-webkit-scrollbar-thumb {
      background: #444;
      border-radius: 5px;
    }

    .inner {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .participant {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .name-icon {
      display: flex;
      flex-direction: row;
      width: 100%;
      img {
        width: 40px;
        height: 40px;
      }
    }

    .name {
      font-size: 16px;
    }

    .subtitle {
      padding: 0px;
    }
  }

  &::backdrop {
    background: rgb(0 0 0 / 25%);
  }
`;

const getParticipantLevelAndTitle = (invitation: InvitationResponse, participant: Participant) => {
  const participantHeatLevel = getLevel(invitation, participant);

  const settings = Core.GetInstance().globalSettings;
  const heatToLevelTitle = settings.heatTypeToTitles[invitation.heatType];
  const heatLevelKeys = Object.keys(heatToLevelTitle);
  const heatLevels = heatLevelKeys.map((s) => parseInt(s));
  heatLevels.sort((a, b) => {
    return a - b;
  });
  let minHeatLevelTitle: HeatLevelTitle | null = null;
  for (const heatLevel of heatLevels) {
    if (heatLevel <= participantHeatLevel) {
      minHeatLevelTitle = heatToLevelTitle[heatLevel];
    } else {
      const heatLevelTitle = minHeatLevelTitle!;
      return { participantHeatLevel, heatLevelTitle };
    }
  }
  throw new Error("unreachable code");
};

const getLevel = (invitation: InvitationResponse, participant: Participant): number => {
  const settings = Core.GetInstance().globalSettings;
  const heatLevelToHeat = settings.heatTypeToLevels[invitation.heatType];
  const recentHeat = participant.recentHeat[invitation.heatType];
  let totalHeat = 0;
  const heatLevels = Object.keys(heatLevelToHeat).map((s) => parseInt(s));
  heatLevels.sort((a, b) => {
    return a - b;
  });
  let minHeatLevel = 1;
  for (const heatLevel of heatLevels) {
    const heat = heatLevelToHeat[heatLevel];
    totalHeat += heat;
    if (totalHeat <= recentHeat) {
      minHeatLevel = heatLevel;
    } else {
      return minHeatLevel;
    }
  }
  throw new Error("unreachable code to make the linter happy");
};

export const HeatBadge: FC<{ value: number }> = ({ value }) => {
  const [currentValue, setCurrentValue] = React.useState(0);
  const [targetValue, setTargetValue] = React.useState(0);
  const [animatingGlow, setAnimatingGlow] = React.useState(false);

  const participants = store.use.participants();
  const invitation = store.use.invitation();

  useEffect(() => {
    setTargetValue(value);
  }, [value]);

  // Lerp the value
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue((currentValue) => {
        if (Math.abs(currentValue - targetValue) < 0.8) {
          clearInterval(interval);
          setAnimatingGlow(true);
          setTimeout(() => {
            setAnimatingGlow(false);
          }, 1500);
          return targetValue;
        }
        return currentValue + 0.1 * (targetValue - currentValue);
      });
    }, 50);

    return () => {
      clearInterval(interval);
      setCurrentValue(targetValue);
    };
  }, [targetValue]);

  return (
    <>
      <Wrapper popoverTarget="heatPopover" title="Open Recent Heat Modal">
        <div className={animatingGlow ? "glow animate" : "glow"} />

        <Flame fill={"#ff8c1a"} />

        <FlipNumbers
          height={18}
          width={10}
          color="#ff8c1a"
          background="transparent"
          play
          perspective={1000}
          numbers={String(Math.floor(currentValue))}
        />
      </Wrapper>
      <HeatPopover id="heatPopover" popover="auto">
        <div className="content">
          <div className="title">
            <div>
              <Flame fill={"#ff8c1a"} width="15px" height="22px" />
              Recent Heat
            </div>
            <button
              className="close"
              title="Close Recent Heat Modal"
              // eslint-disable-next-line react/no-unknown-property
              popoverTarget="heatPopover"
              // eslint-disable-next-line react/no-unknown-property
              popoverTargetAction="toggle"
            >
              <Close stroke="#fff" />
            </button>
          </div>
          <div className="subtitle">A higher group average unlocks more advanced flows</div>

          <div className="participantList">
            <div className="inner">
              {participants &&
                Object.values(participants).map((participant: Participant) => {
                  const { participantHeatLevel, heatLevelTitle } = getParticipantLevelAndTitle(invitation, participant);
                  return (
                    <span key={participant.id}>
                      <div className="participant" key={`p-${participant.id}`}>
                        <div className="name-icon">
                          <img
                            src={`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}${heatLevelTitle.imageUrl}`}
                            alt={heatLevelTitle.title}
                          />
                          <div>
                            <div className="name">{participant.name}</div>
                            <div className="subtitle">{heatLevelTitle.title}</div>
                          </div>
                        </div>
                        <div className="heat">{participant.recentHeat[invitation.heatType] || 0}</div>
                      </div>
                    </span>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="popoverGlow" />
      </HeatPopover>
    </>
  );
};
