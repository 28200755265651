import styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../../../assets/icons/Icons";

export const TileNoVideoWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  button {
    font-size: 14px;
    padding: 5px 15px;
  }
`;

const locationToMessage = (location: Participant["location"]) => {
  switch (location) {
    case "offline":
      return (
        <FormattedMessage
          description="Text when case is offline, indicating disconnected"
          id="tile_no_video.disconnected"
          defaultMessage="Disconnected"
        />
      );

    case "ready-room":
      return (
        <FormattedMessage
          description="Text when case is ready-room, indicating getting ready"
          id="tile_no_video.getting_ready"
          defaultMessage="Getting ready"
        />
      );

    case "session":
      return "";

    default:
      return "";
  }
};
const TileNoVideo = ({
  participantLocation,
  name,
  isLocal,
}: {
  participantLocation: Participant["location"];
  name: string;
  isLocal: boolean;
}) => {
  return (
    <TileNoVideoWrapper>
      {isLocal ? (
        <FormattedMessage
          description="Tile No Video text indicating the username is You"
          id="tile_no_video.username_you"
          defaultMessage="You"
        />
      ) : (
        name
      )}
      {participantLocation !== "session" && (
        <>
          <div>
            <FormattedMessage
              description="Tile No Video location tile text"
              id="tile_no_video.location"
              defaultMessage="{locationMessage}"
              values={{
                locationMessage: locationToMessage(participantLocation),
              }}
            />
          </div>

          {participantLocation === "ready-room" && (
            <div className="spinner">
              &#32;
              <Spinner />
            </div>
          )}
        </>
      )}
    </TileNoVideoWrapper>
  );
};

export default TileNoVideo;
