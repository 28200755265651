import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { FlowItem } from "./FlowItem";

const FlowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
`;

interface Props {
  flows: LobbyInvitation[];
}

export const FlowsList: FC<Props> = ({ flows }) => {
  const storeScrollPosition = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    sessionStorage.setItem("flowsScrollPosition", target.scrollTop.toString());
  };

  // Restore scroll position
  useEffect(() => {
    const target = document.getElementById("flows-list") as HTMLDivElement;
    target.scrollTop = Number(sessionStorage.getItem("flowsScrollPosition"));
  }, []);

  return (
    <FlowsWrapper onScroll={storeScrollPosition} id="flows-list">
      {flows.map((flow) => {
        return <FlowItem key={flow.hashId} flow={flow} data-testid={`flow-${flow.hashId}`} mode="forward" />;
      })}
    </FlowsWrapper>
  );
};
