import DailyIframe, {
  DailyEventObjectParticipant,
  DailyEventObjectParticipantLeft,
  DailyEventObjectActiveSpeakerChange,
  DailyEventObjectAppMessage,
} from "@daily-co/daily-js";
import getToken from "../../lib/requests/getToken";
import toast, { createToast } from "react-simple-toasts";
import React from "react";
import { useStore } from "../store";

export const setupCallObject = async (
  invitation: InvitationResponse,
  clientID: string,
  userName: string,
  onParticipantJoined: (event?: DailyEventObjectParticipant) => void,
  onParticipantLeft: (event?: DailyEventObjectParticipantLeft) => void,
  onActiveSpeakerChange: (event?: DailyEventObjectActiveSpeakerChange) => void,
  transitioning: boolean,
) => {
  console.debug("[session-ui]: Setting up call object");

  const dailyTokenResponse = await getToken(invitation.hashedID, clientID);
  const url = `https://warmspace.daily.co/${invitation.roomName}`;

  let callObject = DailyIframe.getCallInstance();

  if (callObject) {
    console.debug("[session-ui]: Daily iframe already exists, leaving meeting.");
    await callObject.leave();
  }

  if (!callObject) {
    callObject = DailyIframe.createCallObject({ strictMode: true });

    callObject.on("participant-joined", onParticipantJoined);
    callObject.on("participant-left", onParticipantLeft);
    callObject.on("active-speaker-change", onActiveSpeakerChange);

    callObject.on("call-instance-destroyed", (e) => {
      console.error("callInstanceDestroyed Event", e);
    });

    callObject.on("error", (e) => {
      console.error("callObjectError Event", e);
    });

    // Enable closed captioning
    callObject.on("app-message", (msg: DailyEventObjectAppMessage | undefined) => {
      const data = msg?.data;
      if (msg?.fromId === "transcription" && data?.is_final && useStore.getState().closedCaptionsEnabled) {
        const local = callObject!.participants().local;
        const name: string =
          local.session_id === data.session_id
            ? local.user_name
            : callObject!.participants()[data.session_id].user_name;
        const text: string = data.text;
        const timestamp: string = data.timestamp;

        if (name.length && text.length && timestamp.length) {
          createToast({
            duration: 4000,
            clickClosable: true,
            maxVisibleToasts: 3,
            render: (x) => <>{`${name}: ${text}`}</>,
          })("");
        }
      }
    });
  }

  await callObject.preAuth({
    token: dailyTokenResponse.token,
    url: url,
    dailyConfig: {
      v2CamAndMic: true,
      enableIndependentDevicePermissionPrompts: invitation.muteAll,
    },
    userName: userName.toString() || "",
  });

  callObject.on("camera-error", (e) => {
    console.error("cameraError Event", e);
    toast("Unable to start camera: " + e.errorMsg.errorMsg, {
      duration: 1500,
    });
  });

  if (!transitioning) {
    callObject.setLocalVideo(false);
    callObject.setLocalAudio(false);
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  const previousCamera = localStorage.getItem("warmspace.cameraDeviceId");
  const previousMic = localStorage.getItem("warmspace.microphoneDeviceId");

  if (previousCamera) {
    await callObject.setInputDevicesAsync({
      videoDeviceId: previousCamera,
    });
  }

  if (previousMic) {
    await callObject.setInputDevicesAsync({
      audioDeviceId: previousMic,
    });
  }

  if (!transitioning) {
    devices.forEach((device) => {
      if (device.kind === "videoinput" && device.deviceId) {
        callObject.setLocalVideo(true);
        callObject.setLocalAudio(true);
      }
    });
  }

  await callObject.startLocalAudioLevelObserver(100);
  await callObject.startRemoteParticipantsAudioLevelObserver(100);

  return callObject;
};
