let audioContext: AudioContext;

function loadAudioFile(soundFileUrl: string) {
  return fetch(soundFileUrl).then((response: Response) => {
    return response.arrayBuffer().then((arrayBuffer) => {
      return audioContext.decodeAudioData(arrayBuffer);
    });
  });
}

export const SFX2: {
  loadAudios: () => void;
  playAudio: (audioFile: AudioBuffer | undefined, volume: number) => void;
  timeChime?: AudioBuffer;
  tripleTimeChime?: AudioBuffer;
  newChatMessage?: AudioBuffer;
  partnerDisconnected?: AudioBuffer;
  partnerArrived?: AudioBuffer;
  recordingStarted?: AudioBuffer;
  waitingRoomArrived?: AudioBuffer;
} = {
  loadAudios: () => {
    console.debug("[session-ui]: Loading sound effects files.");

    if (!audioContext) {
      // Initialize the audio context on the first user interaction
      // @ts-ignore
      audioContext = new (window.AudioContext || window.webkitAudioContext)();

      const timeChimePromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-30-07/sfx/sfx-time-chime.mp3",
      );
      const tripleTimeChimePromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2024-12-04/sfx/sfx-triple-time-chime.mp3",
      );
      const newChatMessagePromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-30-07/sfx/sfx-chat-ping.mp3",
      );
      const partnerDisconnectedPromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-11-23/sfx/sfx-disconnect.mp3",
      );
      const partnerArrivedPromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2023-11-23/sfx/sfx-arrive.mp3",
      );
      const recordingStartedPromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2024-09-08/sfx/sfx-recording-started.mp3",
      );
      const waitingRoomArrivedPromise = loadAudioFile(
        "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2024-11-24/sfx/sfx-waiting-room-arrive.mp3",
      );

      timeChimePromise.then((audioBuffer) => (SFX2.timeChime = audioBuffer));
      tripleTimeChimePromise.then((audioBuffer) => (SFX2.tripleTimeChime = audioBuffer));
      newChatMessagePromise.then((audioBuffer) => (SFX2.newChatMessage = audioBuffer));
      partnerDisconnectedPromise.then((audioBuffer) => (SFX2.partnerDisconnected = audioBuffer));
      partnerArrivedPromise.then((audioBuffer) => (SFX2.partnerArrived = audioBuffer));
      recordingStartedPromise.then((audioBuffer) => (SFX2.recordingStarted = audioBuffer));
      waitingRoomArrivedPromise.then((audioBuffer) => (SFX2.waitingRoomArrived = audioBuffer));
    }
  },

  playAudio: (audioBuffer: AudioBuffer | undefined, volume: number = 1) => {
    if (!audioBuffer) {
      console.error("No audio buffer to play");
      return;
    }

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.loop = false;

    const gainNode = audioContext.createGain(); // Create the GainNode
    source.connect(gainNode);
    gainNode.connect(audioContext.destination);
    gainNode.gain.value = volume;
    source.start(0);
  },
};
