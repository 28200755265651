import axios from "axios";
import { AnalyticsResponse } from "../../components/UI/AnalyticsWidget/AnalyticsWidget";

export default async (analyticsUrl: string): Promise<AnalyticsResponse> => {
  return axios
    .get(analyticsUrl, {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
};
