import React from "react";
import { ScreenShare } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { useScreenShare } from "@daily-co/daily-react";
import DailyIframe from "@daily-co/daily-js";

export const ScreenShareButton = () => {
  const { isSharingScreen, startScreenShare, stopScreenShare, screens } = useScreenShare();
  const browserInfo = DailyIframe.supportedBrowser();
  const toggleScreenShare = () => (isSharingScreen ? stopScreenShare() : startScreenShare());

  return browserInfo.supportsScreenShare && (screens.length == 0 || isSharingScreen) ? (
    <MenuButton
      buttonIcon={<ScreenShare stroke={isSharingScreen ? "#FFA100" : "white"} />}
      onClick={() => {
        toggleScreenShare();
      }}
    >
      <FormattedMessage
        description="Menu button text to toggle on screenshare"
        id="screenshare_button"
        defaultMessage="Share"
      />
    </MenuButton>
  ) : (
    <></>
  );
};
