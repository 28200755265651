import React from "react";
import { AddPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store, useStore } from "../../../core/store";

export const AddPartnerButton = () => {
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const participants = store.use.participants();
  const showAddPartner = store.use.showAddPartner();

  const curParticipant = participants[currentUser.id];

  const toggleShowAddPartner = () => {
    useStore.setState((state) => ({ showAddPartner: !state.showAddPartner }));
  };

  if (!curParticipant?.personalCode || invitation.lobby || !invitation.partnerJoinable) {
    return;
  }

  return (
    <MenuButton
      buttonIcon={<AddPartnerIcon stroke={showAddPartner ? "#FFA100" : "white"} />}
      onClick={toggleShowAddPartner}
      className={showAddPartner ? "selected" : ""}
    >
      <FormattedMessage
        description="Menu button text to toggle Add Partner"
        id="add_partner.add_partner"
        defaultMessage="Add Partner"
      />
    </MenuButton>
  );
};
