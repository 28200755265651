import { Core } from "../core";
import { ClearLoaderFunc } from "../../lib/requests/postAction";

export const makeParticipantFacilitator = function (
  participantID: number,
  enabled: boolean,
  clearLoader?: ClearLoaderFunc,
) {
  const core = Core.GetInstance();
  core.subscription?.perform("make_facilitator", { participantId: participantID, enabled: enabled }, clearLoader);
};
