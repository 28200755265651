import styled from "styled-components";
import React from "react";
import { BreakoutRoomIcon, ClockIcon, Close, Leave } from "../../../assets/icons/Icons";
import { doAction } from "../../../core/SessionChannel/doAction";
import postBreakoutReturn from "../../../lib/requests/postBreakoutReturn";
import { store } from "../../../core/store";
import { FormattedMessage } from "react-intl";
import useCountdown from "../../../hooks/useCountDown";
import { modalClear, modalShow, modalToggle } from "../Modal/Modals";
import * as linkify from "linkifyjs";
import { HeatBadge } from "./HeatBadge";

const Status = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: 33px;

  font-weight: 900;
  font-size: 14px;
  line-height: normal;
  background: transparent;

  box-sizing: border-box;

  color: #eee;

  gap: 5px;
  z-index: 115;

  div.buttons {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-self: flex-end;
    margin-left: auto;
  }

  .title {
    flex-shrink: 1;
    overflow: hidden;
  }

  & .clickable-icon {
    cursor: pointer;
  }
`;

const LobbyTitle = styled.div`
  align-items: center;
  text-transform: uppercase;
  gap: 5px;
  flex-shrink: 1;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CountdownBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  z-index: -1;
  width: 100%;
  background: #da3a00;
  transition: width 1s linear;
  opacity: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;

  margin: auto;

  height: 40px;
  overflow: hidden;
  padding: 0px 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 5px;

  width: 100%;
  box-sizing: border-box;
  opacity: 1;
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  flex-shrink: 0;
  width: auto;

  border-radius: 8px;

  font-size: 14px;
  line-height: 22px;
  background-color: #222;

  font-weight: bold;
  padding: 4px 10px;

  display: flex;
  align-items: center;
  gap: 5px;

  border-top: 1px solid hsl(0, 0%, 23%);

  svg {
    flex-shrink: 0;
    width: 17px;
    height: 17px;
    top: -1px;
    position: relative;
  }

  &:hover {
    background-color: #333;
  }

  &.go-button {
    background: linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%);
    border-top: 1px solid hsl(25.4, 100%, 68%);

    &:hover {
      background: linear-gradient(90deg, hsl(25.4, 100%, 59%) 0.21%, hsl(25.4, 100%, 65%) 122.48%);
    }
  }
`;

const TimeRemaining = styled.span`
  color: #f5f5f5;
  font-size: 12px;
  font-size: 14px;
  line-height: 22px;
  background-color: #333;
  font-weight: bold;
  padding: 4px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 14px;
    top: -1px;
  }
`;

const LeaveButton = styled.button`
  background: none;
  border: none;
  flex-shrink: 0;
  border-radius: 8px;

  font-size: 14px;
  line-height: 22px;
  height: 30px;
  background-color: #833;
  border-top: 1px solid #966;

  font-weight: bold;
  padding: 4px 7px;

  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    top: -1px;
    width: 24px;
  }

  &:hover {
    background-color: #a53535 !important;
    position: relative;
  }
`;

export function secondsToTimeString(actualSecondsRemaining: number | number) {
  const secondPortionRemaining = actualSecondsRemaining % 60;
  const secondsString = secondPortionRemaining <= 9 ? `0${secondPortionRemaining}` : secondPortionRemaining;
  return `${Math.trunc(actualSecondsRemaining / 60)}:${secondsString}`;
}

export const LobbyStatusBar = ({
  title,
  inRoom,
  expirationDate,
}: {
  title: string;
  inRoom: boolean;
  expirationDate?: Date;
}) => {
  const confirmLeaveModalOpen = store.use.showModals().includes("ConfirmLeave");
  const participants = store.use.participants();
  const deviceClassification = store.use.deviceClassification();
  const inPerson = store.use.inPerson();
  const sessionClosingType = store.use.sessionClosingType();
  const currentUser = store.use.currentUser();
  const core = store.use.core();
  const invitation = store.use.invitation();
  const recentHeat = store.use.recentHeat();
  const heat = recentHeat[invitation.heatType] || 0;

  const isRoomClosing = inRoom && sessionClosingType == "closing";
  const isAutoLaunch = !inRoom && sessionClosingType == "auto_launch";
  const isNewPageTransition = !inRoom && sessionClosingType == "new_page";

  const actualSecondsRemaining = useCountdown(expirationDate);
  const secondsUntilBarVisible = inRoom ? 20 : isAutoLaunch ? 60 : isNewPageTransition ? 30 : 30;
  const timeRemainingString = secondsToTimeString(actualSecondsRemaining);
  const autoLaunchFlowName = invitation.autoLaunchFlowName;
  const meetingLinkName = !invitation.endLobbyUrl
    ? null
    : invitation.endLobbyUrl?.indexOf("zoom.") != -1
      ? "Zoom"
      : invitation.endLobbyUrl?.indexOf("microsoft.") != -1
        ? "Teams"
        : invitation.endLobbyUrl?.indexOf("google.") != -1
          ? "Meet"
          : "Meeting";

  const showCountdownBar =
    (isRoomClosing || isAutoLaunch || isNewPageTransition) &&
    actualSecondsRemaining <= secondsUntilBarVisible &&
    actualSecondsRemaining > 0;

  const toggleQrCodeModal = () => {
    modalToggle("QrCode");
  };

  function getExternalMeetingLink() {
    const links = linkify.find(invitation.endLobbyUrl!);
    const firstLink = links[0];
    let linkText = firstLink.value;
    if (!linkText.startsWith("http")) {
      linkText = "https://" + linkText;
    }
    return linkText!;
  }

  return (
    <Wrapper
      className={[
        deviceClassification.size,
        actualSecondsRemaining > 0 ? "countingDown" : "",
        inPerson ? "inPerson" : "",
      ].join(" ")}
    >
      <Status>
        {invitation.testEnabled && !showCountdownBar && !inRoom && <HeatBadge value={heat} />}
        <div className="title">
          {(!actualSecondsRemaining || actualSecondsRemaining > secondsUntilBarVisible) && (
            <LobbyTitle className="clickable-icon" onClick={toggleQrCodeModal}>
              {isRoomClosing && actualSecondsRemaining > 0 && (
                <TimeRemaining>
                  <ClockIcon stroke="white" /> {timeRemainingString}
                </TimeRemaining>
              )}
              {title}
            </LobbyTitle>
          )}
          {actualSecondsRemaining != 0 &&
            actualSecondsRemaining <= secondsUntilBarVisible &&
            ((isRoomClosing && (
              <FormattedMessage
                id="lobby_status_badge.room_closing_in"
                defaultMessage="Session ending in {actualSecondsRemaining} seconds"
                values={{ actualSecondsRemaining }}
              />
            )) ||
              (isAutoLaunch && (
                <FormattedMessage
                  id="lobby_status_badge.auto_launching_flow_in"
                  defaultMessage="{autoLaunchFlowName} in {actualSecondsRemaining}"
                  values={{ autoLaunchFlowName, actualSecondsRemaining }}
                />
              )) ||
              (isNewPageTransition && (
                <FormattedMessage
                  id="lobby_status_badge.new_page_transition_in"
                  defaultMessage="Go to {meetingLinkName} in {actualSecondsRemaining}"
                  values={{ meetingLinkName, actualSecondsRemaining }}
                />
              )))}
          {showCountdownBar && (
            <CountdownBar style={{ width: (actualSecondsRemaining / secondsUntilBarVisible) * 100 + "%" }} />
          )}
        </div>

        <div className="buttons">
          {inRoom ? (
            <LinkButton
              onClick={() => {
                doAction(core, "end_session", -1, -1);
              }}
            >
              <FormattedMessage id="lobby_status_badge.leave_room" defaultMessage="Back to lobby" />
            </LinkButton>
          ) : showCountdownBar ? (
            <LinkButton onClick={() => core.subscription?.perform("cancel_lobby_transition")}>
              <FormattedMessage id="lobby_status_badge.cancel_auto_launch" defaultMessage="Cancel" />
            </LinkButton>
          ) : (
            participants[currentUser.id]?.breakoutRoomHashId && (
              <LinkButton
                className="join"
                onClick={() => {
                  postBreakoutReturn({
                    roomInvitationHashId: participants[currentUser.id].breakoutRoomHashId || "",
                    inqUserId: currentUser.id,
                  });
                }}
              >
                {inRoom ? null : <BreakoutRoomIcon stroke="white" />}
                {inRoom ? "" : <FormattedMessage id="lobby_status_badge.breakout" defaultMessage="Re-join flow" />}
              </LinkButton>
            )
          )}

          {!inRoom && meetingLinkName && (!isAutoLaunch || !showCountdownBar) && (
            <LinkButton className="go-button" onClick={() => (window.location.href = getExternalMeetingLink())}>
              <FormattedMessage
                id="lobby_status_badge.go_to_meeting_url"
                defaultMessage="Go to {meetingLinkName}"
                values={{ meetingLinkName }}
              />
            </LinkButton>
          )}

          {!inRoom && isAutoLaunch && showCountdownBar && (
            <LinkButton className="go-button" onClick={() => core.subscription?.perform("run_lobby_transition")}>
              <FormattedMessage
                id="lobby_status_badge.go_to_meeting_url"
                defaultMessage="Start {autoLaunchFlowName}"
                values={{ autoLaunchFlowName }}
              />
            </LinkButton>
          )}

          {!showCountdownBar && (
            <LeaveButton
              style={{
                zIndex: confirmLeaveModalOpen ? 115 : 0,
                borderBottomWidth: confirmLeaveModalOpen ? 0 : "100px",
              }}
              onClick={() => {
                if (confirmLeaveModalOpen) {
                  modalClear("ConfirmLeave");
                } else {
                  modalShow("ConfirmLeave", true);
                }
              }}
            >
              {confirmLeaveModalOpen ? <Close stroke="#fff" width="40px" height="40px" /> : <Leave fill="white" />}
            </LeaveButton>
          )}
        </div>
      </Status>
    </Wrapper>
  );
};
