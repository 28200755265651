import styled from "styled-components";
import React, { useState, FC } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close } from "../../../assets/icons/Icons";
import { Overlay, Card, CardBody, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { store } from "../../../core/store";
import { can } from "../../../helpers/can";
import { muteAll } from "../../../core/SessionChannel/muteAll";
import { modalClearAll } from "../Modal/Modals";
import { ManageParticipantItem } from "./ManageParticipantItem";
import { admitAll } from "../../../core/SessionChannel/admitAll";
import { toggleWaitingRoom } from "../../../core/SessionChannel/toggleWaitingRoom";

const ManageParticipantsWrapper = styled.div`
  max-height: 60vh;
  overflow: auto;
  width: 100%;
  padding-bottom: 10px;
`;

const ManageParticipantsFooter = styled.div`
  width: 100%;
  .footer {
    height: 42px;
  }
  .button {
    margin: 0 auto;
  }
`;

const DropParticipantModalWrapper = styled.div`
  display: flex;
  button {
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
  }
  .cancelButton {
    border: none;
    background-color: transparent;
    padding: 0px;
    text-decoration: underline;
    box-shadow: none !important;
    padding: 0px !important;
    color: #222;
  }
`;

export const SmallButton = styled(Button)`
  background: #222;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 10px;
`;

interface Props {
  joinedParticipants: Participant[];
  dropParticipant: (participantID: number) => void;
  yourId: number;
}
export const ManageParticipantModal: FC<Props> = ({ joinedParticipants, dropParticipant, yourId }) => {
  const intl = useIntl();
  const [showDropParticipantModal, setShowDropParticipantsModal] = useState(false);
  const [participant, setParticipant] = useState<Participant>();

  const inPerson = store.use.inPerson();
  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();

  const mutingEnabled = can("muteOthers", currentUser, invitation) && !inPerson;

  const participantSort = (a: Participant, b: Participant) => {
    // Make the current user always first in the list for self-managing purposes
    if (a.name == currentUser.name) {
      return -1;
    } else if (b.name == currentUser.name) {
      return 1;
    }

    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  };

  //Splitting array for offline vs online user
  const [offlineParticipants, onlineParticipants, waitingRoomParticipants] = joinedParticipants.reduce(
    (result, p) => {
      result[p.location === "waitingRoom" ? 2 : p.location === "offline" ? 0 : 1].push(p);
      return result;
    },
    [new Array<Participant>(), new Array<Participant>(), new Array<Participant>()],
  );

  const canManageParticipants = can("manageParticipants", currentUser, invitation);

  //Sorting array by name
  offlineParticipants.sort(participantSort);
  onlineParticipants.sort(participantSort);
  waitingRoomParticipants.sort(participantSort);

  const renderParticipants = (participant: Participant, index: number) => {
    return (
      <ManageParticipantItem
        participant={participant}
        mutingEnabled={mutingEnabled}
        yourId={yourId}
        index={index}
        setShowDropParticipantsModal={setShowDropParticipantsModal}
        setParticipant={setParticipant}
        key={participant.id}
        canManageParticipants={canManageParticipants}
        currentUser={currentUser}
        inPerson={invitation.inPerson}
      />
    );
  };

  return (
    <>
      {!showDropParticipantModal ? (
        <Card data-testid="participants-modal">
          <CardHeader>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <FormattedMessage
                description="Participants Modal header text"
                id="manage_participant_modal.header"
                defaultMessage="Participants"
              />
              &nbsp;
              <span
                title={intl.formatMessage({
                  description: "Text indicating online participants on participant modal",
                  id: "manage_participant_modal.online_participants",
                  defaultMessage: "Online participants",
                })}
              >
                <FormattedNumber value={onlineParticipants.length} />
              </span>
              <FormattedMessage id="entities.slash" defaultMessage="&#47;" />
              <span
                title={intl.formatMessage({
                  description: "Text indicating total participants",
                  id: "manage_participant_modal.total_participants",
                  defaultMessage: "Total participants",
                })}
              >
                <FormattedNumber value={joinedParticipants.length} />
              </span>
              {mutingEnabled && (
                <>
                  <SmallButton
                    onClick={() => {
                      muteAll(true);
                    }}
                    style={{ width: "auto" }}
                  >
                    <FormattedMessage
                      description="Button text to mute all participants"
                      id="manage_participant_modal.mute_all"
                      defaultMessage="Mute all"
                    />
                  </SmallButton>
                  <SmallButton
                    onClick={() => {
                      muteAll(false);
                    }}
                    style={{ width: "auto" }}
                  >
                    <FormattedMessage
                      description="Button text to unmute all participants"
                      id="manage_participant_modal.unmute_all"
                      defaultMessage="Unmute all"
                    />
                  </SmallButton>
                </>
              )}
            </div>

            <Button
              className="link"
              onClick={() => {
                modalClearAll();
              }}
            >
              <Close />
            </Button>
          </CardHeader>
          <CardBody>
            <ManageParticipantsWrapper>
              {waitingRoomParticipants.map((p, i) => renderParticipants(p, i))}
              {offlineParticipants.map((p, i) => renderParticipants(p, i))}
              {onlineParticipants.map((p, i) => renderParticipants(p, i))}
            </ManageParticipantsWrapper>
          </CardBody>
          {canManageParticipants && (
            <ManageParticipantsFooter>
              <CardFooter className="footer">
                {waitingRoomParticipants.length > 0 && (
                  <SmallButton
                    onClick={() => {
                      admitAll();
                    }}
                    className="button"
                  >
                    <FormattedMessage
                      description="Button text to move all waiting room participants into the session"
                      id="manage_participant_modal.admit_all"
                      defaultMessage="Admit All"
                    />
                  </SmallButton>
                )}
                {invitation.waitingRoomEnabled && (
                  <SmallButton
                    onClick={() => {
                      toggleWaitingRoom(false);
                    }}
                    className="button"
                  >
                    <FormattedMessage
                      description="Button text to close the waiting room and move all waiting room participants into the session"
                      id="manage_participant_modal.turn_off_waiting_room"
                      defaultMessage="Turn Off Waiting Room"
                    />
                  </SmallButton>
                )}
                {!invitation.waitingRoomEnabled && (
                  <SmallButton
                    onClick={() => {
                      toggleWaitingRoom(true);
                    }}
                    className="button"
                  >
                    <FormattedMessage
                      description="Button text to start the waiting room"
                      id="manage_participant_modal.turn_on_waiting_room"
                      defaultMessage="Turn On Waiting Room"
                    />
                  </SmallButton>
                )}
              </CardFooter>
            </ManageParticipantsFooter>
          )}
        </Card>
      ) : (
        <DropParticipantModalWrapper data-testid={"drop-participant-modal"}>
          <Card>
            <CardHeader>
              <FormattedMessage
                description="Drop participant header text indicating which participant to drop from the session"
                id="manage_participant_modal.drop_from_the_session"
                defaultMessage="Drop {participant_name} from the session"
                values={{ participant_name: participant?.name }}
              />
            </CardHeader>
            <CardBody>
              <FormattedMessage
                description="Text confirmation to drop participant from session"
                id="manage_participant_modal.drop_confirmation"
                defaultMessage="Are you sure you want to drop {participant_name} from the session?"
                values={{ participant_name: participant?.name }}
              />
            </CardBody>
            <CardFooter>
              <Button
                onClick={async () => {
                  setShowDropParticipantsModal(false);
                }}
                className="link"
                style={{ color: "black" }}
              >
                <FormattedMessage
                  description="Cancel managing participants button text"
                  id="manage_participants_modal.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                style={{ width: " auto" }}
                onClick={async () => {
                  if (participant) {
                    await dropParticipant(participant.id);
                  }
                  setShowDropParticipantsModal(false);
                }}
              >
                <FormattedMessage
                  description="Drop participant button text"
                  id="manage_participants_modal.drop_participant"
                  defaultMessage="Drop {participant_name}"
                  values={{ participant_name: participant?.name }}
                />
              </Button>
            </CardFooter>
          </Card>
        </DropParticipantModalWrapper>
      )}
      <Overlay
        onClick={() => {
          modalClearAll();
        }}
        blur={false}
      />
    </>
  );
};

export default ManageParticipantModal;
