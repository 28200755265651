import React from "react";
import { RaisedHand } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store } from "../../../core/store";

export const RaiseHandButton = () => {
  const raisedHands = store.use.raisedHands();
  const core = store.use.core();

  const participantId = core.currentUser.id;
  const handRaised = raisedHands.includes(participantId);

  const toggleHand = () => {
    core.subscription?.perform("toggle_hand", {
      participantId: participantId,
      action: handRaised ? "lower_hand" : "raise_hand",
    });
  };

  return (
    <MenuButton
      buttonIcon={<RaisedHand stroke={handRaised ? "#FFA100" : "white"} />}
      onClick={() => {
        toggleHand();
      }}
      className={handRaised ? "selected" : ""}
    >
      {!handRaised && <FormattedMessage id="raise_hand.raise_label" defaultMessage={"Raise Hand"} />}
      {handRaised && <FormattedMessage id="raise_hand.lower_label" defaultMessage={"Lower Hand"} />}
    </MenuButton>
  );
};
