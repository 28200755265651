import React, { FC } from "react";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { store } from "../../../core/store";
import { Room } from "./Room";
import { groupBy } from "../../../core/util";
import styled from "styled-components";

interface Props {
  rooms: BreakoutRoomMessage[];
}

const FlowHeading = styled.div`
  height: 19px;
  font-weight: bold;
  color: #444;
  font-size: 14px;
  margin-left: 6px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffeecc;
  border-radius: 9px;
  box-sizing: border-box;
  color: #000;
  width: 100%;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffeecc;
  border-radius: 9px;
  box-sizing: border-box;
  color: #000;
  width: 100%;

  &.room-group {
    border: 2px solid coral;

    .room-name {
      color: #ff612e;
      position: relative;
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;
      left: 6px;
      top: 6px;
      width: calc(100% - 6px);
    }
  }
`;

export function getRoomParticipants(room: BreakoutRoomMessage, allParticipants: Participants): Participant[] {
  return !room.participantIds
    ? []
    : room.participantIds
        .map((participantId) => allParticipants[participantId])
        .filter((participant) => participant !== undefined);
}

export const RoomsList: FC<Props> = ({ rooms }) => {
  const allParticipants = store.use.allParticipants();
  const locale = store.use.locale();
  const invitation = store.use.invitation();

  const lobbyInvitations = invitation.lobbyInvitations;
  const parentHashIdToRooms = groupBy(rooms, (r) => r.parentHashId);

  // Sort each rooms list by start time, descending
  Object.values(parentHashIdToRooms).forEach((rooms) =>
    rooms.sort((roomA, roomB) => roomB.roomStartTime.localeCompare(roomA.roomStartTime)),
  );

  // Sort the parentHashIds by start time, descending
  const parentHashIds = Object.keys(parentHashIdToRooms);
  parentHashIds.sort((parentHashIdA, parentHashIdB) =>
    parentHashIdToRooms[parentHashIdB][0].roomStartTime.localeCompare(
      parentHashIdToRooms[parentHashIdA][0].roomStartTime,
    ),
  );

  return parentHashIds.map((parentHashId) => {
    const invitation = lobbyInvitations.find((flow) => flow.hashId == parentHashId);
    const rooms = parentHashIdToRooms[parentHashId];

    const groupHashIdToRooms = groupBy(rooms, (r) => r.groupHashId || "n/a");

    // Sort each rooms list by start time, descending
    Object.values(groupHashIdToRooms).forEach((rooms) =>
      rooms.sort((roomA, roomB) => roomB.roomStartTime.localeCompare(roomA.roomStartTime)),
    );

    const groupHashIds = Object.keys(groupHashIdToRooms);
    groupHashIds.sort((groupHashIdA, groupHashIdB) =>
      groupHashIdToRooms[groupHashIdB][0].roomStartTime.localeCompare(
        groupHashIdToRooms[groupHashIdA][0].roomStartTime,
      ),
    );

    return (
      <Wrapper key={invitation!.hashId}>
        {parentHashIds.length >= 1 && <FlowHeading>{invitation!.name}</FlowHeading>}
        {groupHashIds.map((groupHashId) => {
          const groupRooms = groupHashIdToRooms[groupHashId];
          groupRooms.sort((roomA, roomB) => roomA.roomName.localeCompare(roomB.roomName));
          let lastGroupName = "";
          return (
            <GroupWrapper className={groupHashId == "n/a" ? "" : "room-group"} key={groupHashId}>
              {groupRooms.map((room, idx) => {
                return (
                  <>
                    {lastGroupName != room.roomName && groupHashId != "n/a" ? (
                      <div className="room-name">{(lastGroupName = room.roomName)}</div>
                    ) : null}
                    <Room
                      locale={locale}
                      key={room.hashId}
                      room={room}
                      idx={idx}
                      getRoomParticipants={getRoomParticipants}
                      allParticipants={allParticipants}
                      showBackNavigation={false}
                    />
                  </>
                );
              })}
            </GroupWrapper>
          );
        })}
      </Wrapper>
    );
  });
};
