/* eslint-disable formatjs/no-literal-string-in-jsx */
import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { ParticipantsButton } from "./ParticipantsButton";
import SwitchDigitalPhysicalButton from "./SwitchDigitalPhysicalButton";
import BlurBackground from "./BlurBackground";
import { store, useStore } from "../../../core/store";
import { useFullscreenHandler } from "../../../hooks/useFullscreenhandler";
import MenuButton from "./MenuButton";
import expand from "../../../assets/icons/expand.svg";
import compress from "../../../assets/icons/compress.svg";
import { FormattedMessage } from "react-intl";
import { Video, Microphone, Speaker } from "../../../assets/icons/Icons";
import { Menu, TextInput } from "grommet";
import { useDevices } from "@daily-co/daily-react";
import { modalClear } from "../Modal/Modals";
import { RaiseHandButton } from "./RaiseHandButton";
import { RecordButton } from "./RecordButton";
import toast from "react-simple-toasts";
import { updateEndLobbyUrl } from "../../../core/SessionChannel/updateEndLobbyUrl";
import { can } from "../../../helpers/can";
import { updateAutoLaunchFlow } from "../../../core/SessionChannel/updateAutoLaunchFlow";
import ClosedCaptionsButton from "./ClosedCaptionsButton";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  background-color: #222;
  padding: 10px 10px;

  border-radius: 10px;
  box-sizing: border-box;

  position: relative;
  z-index: 10;
  color: #fff;
`;

// const EmojisWrapper = styled.div`
//   display: flex;
//   aling-items: center;
//   justify-content: space-evenly;
//   padding: 8px;
// `;

const SeparatorTop = styled.div`
  width: 100%;
  height: 0;
  margin: 10px auto 5px;

  border-top: 1px solid #444;
  border-bottom: 1px solid #111;
`;

const SeparatorBottom = styled.div`
  width: 100%;
  height: 0;
  margin: 5px auto 10px;

  border-top: 1px solid #444;
  border-bottom: 1px solid #111;
`;

const SubMenuButtons = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  justify-content: space-around;
  width: 100%;

  > div {
    width: 100px;
    flex-shrink: 0;
  }

  box-sizing: border-box;
`;

const DeviceMenu = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  margin: auto;
  margin-bottom: 10px;
  gap: 8px;
  overflow: hidden;

  button {
    border: 1px solid #383838;
    background-color: #333;
    border-radius: 10px;
    width: 33%;
    flex: 1;
    flex-shrink: 0;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        max-width: 100%;
      }
    }

    > div {
      padding: 7px 6px;
    }

    &:hover {
      background-color: #555;
    }
  }
`;

const Label = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  gap: 5px;
  max-width: 100%;

  height: 20px;
  white-space: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    width: 20px;
    flex-shrink: 0;
  }

  &.microphone {
    svg {
      height: 14px;
    }
  }

  svg {
    width: 15px;
  }
`;

const VolumeSliders = styled.div`
  display: flex;
  font-size: 14px;
  gap: 10px;
  width: 90%;
  margin: auto;
  margin-bottom: 10px;
`;

const LobbySettings = styled.div`
  font-size: 14px;
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  gap: 8px;

    button {
        border: 1px solid #383838;
        background-color: #333;
        border-radius: 10px;
        width: 100%;

        > div {
            padding: 5px 9px 4px;

            span {
                height: 19px;
                overflow: hidden;
            }
        }

        &:hover {
            background-color: #555;
        }
`;

const SettingsExternalUrl = styled.span`
  width: 70%;
`;
const SettingsAutoLaunchFlow = styled.span`
  width: 28%;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  input {
    border-width: 0;
    background: #333;
    border-radius: 11px;
    padding: 6px 12px;
    font-weight: 200;
  }
  input:focus {
    box-shadow: none;
  }
`;

const SmallLabel = styled.div`
  font-size: 11px;
  line-height: 18px;
  padding-left: 7px;
  text-align: left;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SavingMessage = styled.span`
  border-radius: 9px;
  font-size: 12px;
  background-color: #444;
  position: absolute;
  width: fit-content;
  padding: 0 18px;
  top: 4px;
  right: 4px;
  color: #eee;

  &.button:hover {
    background-color: #666;
    cursor: pointer;
  }

  &.flashing {
    animation-name: flashing;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: none !important;
  }

  @keyframes flashing {
    0% {
      background-color: #444;
    }
    50% {
      background-color: #666;
    }
    100% {
      background-color: #444;
    }
  }
`;

const VolumeSliderContainer = styled.div`
  text-align: left;
  padding: 4px 17px;
  padding-top: 8px;
  background-color: #333;
  border-radius: 10px;
  width: 100%;

  input {
    width: 100%;
    margin: 0px;
  }
`;

function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length - 1) + "…" : str;
}

const MoreMenu = () => {
  const inPerson = store.use.inPerson();
  const musicVolume = store.use.musicVolume();
  const musicPlaying = store.use.musicPlaying();
  const narrationVolume = store.use.narrationVolume();
  const interfaceRules = store.use.interfaceRules();
  const deviceClassification = store.use.deviceClassification();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const transcribing = store.use.transcribing();

  const {
    currentCam,
    currentMic,
    currentSpeaker,
    refreshDevices,
    cameras,
    microphones,
    speakers,
    setCamera,
    setMicrophone,
    setSpeaker,
  } = useDevices();

  useEffect(() => {
    if (!inPerson) {
      refreshDevices();
    }
  }, [inPerson]);

  function changeMusicVolume(volume: string) {
    if (volume === "0" && musicPlaying) {
      useStore.setState({ musicPlaying: false });
    } else if (volume !== "0" && !musicPlaying) {
      useStore.setState({ musicPlaying: true });
    }
    useStore.setState({ musicVolume: parseFloat(volume) });
  }

  function changeNarrationVolume(volume: string) {
    useStore.setState({ narrationVolume: parseFloat(volume) });
  }

  const { toggleFullscreen, isFullscreen } = useFullscreenHandler();

  const isBiggerScreen = deviceClassification.size !== "small";
  const isLobby = invitation.lobby;

  useEffect(() => {
    if (currentMic?.device.label) {
      toast(`Microphone: ${currentMic?.device.label}`, 2000);
    }
  }, [currentMic?.device.label]);

  const inputRef = useRef(null);

  function selectEndLobbyUrl() {
    const textInput = inputRef.current! as HTMLInputElement;
    textInput.select();
    textInput.setSelectionRange(0, 99999); // For mobile devices according to w3schools.com
  }

  useEffect(() => {
    !inPerson &&
      isLobby &&
      inputRef.current &&
      setTimeout(() => {
        const textInput = inputRef.current as HTMLInputElement | null;
        if (textInput) {
          textInput.value = invitation.endLobbyUrl || "";
        }
      }, 100);
  }, [invitation]);

  const [updateMeetingUrlTimeout, setUpdateMeetingUrlTimeout] = React.useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const [savingMeetingLink, setSavingMeetingLink] = React.useState<boolean>(false);
  const [showSaveMeetingLink, setShowSaveMeetingLink] = React.useState<boolean>(false);

  function onChangeEndLobbyUrl() {
    setShowSaveMeetingLink(false);
    const textInput = inputRef.current! as HTMLInputElement;
    if (textInput.value != invitation.endLobbyUrl) {
      if (updateMeetingUrlTimeout) {
        clearTimeout(updateMeetingUrlTimeout);
      }
      const timeout = setTimeout(() => {
        if (textInput.value != invitation.endLobbyUrl) {
          setShowSaveMeetingLink(true);
        }
      }, 300);
      setUpdateMeetingUrlTimeout(timeout);
    }
  }

  function saveEndLobbyUrl() {
    const textInput = inputRef.current! as HTMLInputElement;
    if (textInput.value != invitation.endLobbyUrl) {
      setSavingMeetingLink(true);
      updateEndLobbyUrl(textInput.value, () => {
        setSavingMeetingLink(false);
      });
    }
    setShowSaveMeetingLink(false);
  }

  const disableAutoLaunch: unknown = {
    name: "[none]",
    hashId: null,
  };

  let flows: LobbyInvitation[] = [];
  if (isLobby && invitation.lobbyInvitations) {
    flows = invitation.lobbyInvitations.slice();
    flows.unshift(disableAutoLaunch as LobbyInvitation);
  }

  const submenuButtons: React.JSX.Element[] = [];

  if (!isBiggerScreen) submenuButtons.push(<ParticipantsButton />);
  if (!inPerson && !isBiggerScreen) submenuButtons.push(<RecordButton />);
  if (!inPerson && !isBiggerScreen && isLobby) submenuButtons.push(<RaiseHandButton />);
  if (!inPerson) submenuButtons.push(<BlurBackground version="v2" />);
  if (interfaceRules.allowModeSwitching) submenuButtons.push(<SwitchDigitalPhysicalButton inPerson={inPerson} />);
  if (transcribing) submenuButtons.push(<ClosedCaptionsButton />);
  submenuButtons.push(
    <MenuButton
      buttonIcon={<img height="18px" src={isFullscreen ? compress : expand} />}
      onClick={() => {
        modalClear("MoreMenu");
        toggleFullscreen(isFullscreen);
      }}
    >
      {isFullscreen && (
        <FormattedMessage
          description="More Menu button text to exit fullscreen "
          id="more_menu.exit-fullscreen"
          defaultMessage={"Exit Fullscreen"}
        />
      )}
      {!isFullscreen && (
        <FormattedMessage
          description="More Menu button text to enter fullscreen"
          id="more_menu.enter-fullscreen"
          defaultMessage={"Fullscreen"}
        />
      )}
    </MenuButton>,
  );

  return (
    <Wrapper>
      {/* <EmojisWrapper>
        <Button className="emoji" title="Thumbs up">
          &#128077;
        </Button>
        <Button className="emoji" title="Red heart">
          &#x2764;&#xFE0F;
        </Button>
        <Button className="emoji" title="Tears of joy">
          &#128514;
        </Button>
        <Button className="emoji" title="Open Mouth">
          &#128558;
        </Button>
        <Button className="emoji" title="Disappointed but relieved face">
          &#128549;
        </Button>
        <Button className="emoji" title="Pray">
          &#128591;
        </Button>
      </EmojisWrapper>
       */}
      {(!invitation.disableMusic || invitation.audioPaths.length > 0) && (
        <VolumeSliders>
          {!invitation.disableMusic && !inPerson && (
            <VolumeSliderContainer>
              <label>Music volume:</label>
              <input
                onChange={(e) => changeMusicVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={invitation.disableMusic ? 0 : musicVolume}
                disabled={invitation.disableMusic}
                data-testid="music-volume"
              />
            </VolumeSliderContainer>
          )}

          {invitation.audioPaths.length > 0 && (
            <VolumeSliderContainer>
              <label>Narration volume:</label>
              <input
                onChange={(e) => changeNarrationVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={narrationVolume}
                data-testid="narration-volume"
              />
            </VolumeSliderContainer>
          )}
        </VolumeSliders>
      )}
      {!inPerson && (
        <>
          <DeviceMenu>
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label>
                  <Video fill="white" width={21} />
                  <span>{currentCam?.device.label || "Camera"}</span>
                </Label>
              }
              items={cameras.map((c) => ({
                label: c.device.label,
                onClick: () => {
                  localStorage.setItem("warmspace.cameraDeviceId", c.device.deviceId);
                  setCamera(c.device.deviceId);
                },
              }))}
            />
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label className="microphone">
                  <Microphone fill="white" />
                  <span>{currentMic?.device.label || "Microphone super long"}</span>
                </Label>
              }
              items={microphones
                .filter((m) => {
                  return !m.device.label.includes("Default");
                })
                .map((m) => ({
                  label: m.device.label,
                  onClick: () => {
                    localStorage.setItem("warmspace.microphoneDeviceId", m.device.deviceId);
                    setMicrophone(m.device.deviceId);
                  },
                }))}
            />
            <Menu
              size="small"
              icon={false}
              dropBackground={"#444"}
              label={
                <Label>
                  <Speaker />
                  <span>{currentSpeaker?.device.label || "Speaker"}</span>
                </Label>
              }
              items={speakers.map((s) => ({
                label: s.device.label,
                onClick: () => {
                  setSpeaker(s.device.deviceId);
                },
              }))}
            />
          </DeviceMenu>
          <SeparatorTop />
        </>
      )}
      <SubMenuButtons key={"SubMenuButtons"}>{submenuButtons.map((button, idx) => button)}</SubMenuButtons>
      {!inPerson && isLobby && can("updateInvitationSettings", currentUser, invitation) && (
        <>
          <SeparatorBottom />
          <LobbySettings>
            <SettingsExternalUrl>
              <SmallLabel>
                <FormattedMessage
                  description="Label in More Menu for an external meeting link"
                  id="more_menu.external_meeting_url"
                  defaultMessage="External Meeting Link for {lobbyName}"
                  values={{ lobbyName: invitation.title }}
                />
              </SmallLabel>
              <InputWrapper>
                <TextInput
                  placeholder="enter a Zoom/Teams/Meet link"
                  ref={inputRef}
                  onFocus={selectEndLobbyUrl}
                  onChange={onChangeEndLobbyUrl}
                />
                {savingMeetingLink && (
                  <SavingMessage className="flashing">
                    <FormattedMessage
                      description="Message in More Menu for when an external meeting link is being updated"
                      id="more_menu.saving_link_message"
                      defaultMessage="Saving..."
                    />
                  </SavingMessage>
                )}
                {showSaveMeetingLink && (
                  <SavingMessage className="button" onClick={saveEndLobbyUrl}>
                    <FormattedMessage
                      description="Button in More Menu to save an external meeting link"
                      id="more_menu.save_link_button"
                      defaultMessage="Save"
                    />
                  </SavingMessage>
                )}
              </InputWrapper>
            </SettingsExternalUrl>

            <SettingsAutoLaunchFlow>
              <SmallLabel>
                <FormattedMessage
                  description="Label in More Menu for an auto-launched flow"
                  id="more_menu.auto_lanch_flow"
                  defaultMessage="Auto-launch Flow"
                />
              </SmallLabel>
              <Menu
                size="small"
                icon={false}
                dropBackground={"#444"}
                label={<Label>{invitation.autoLaunchFlowName || "[none]"}</Label>}
                items={flows.map((flow) => ({
                  label: flow.name,
                  onClick: () => {
                    updateAutoLaunchFlow(flow.hashId);
                  },
                }))}
              />
            </SettingsAutoLaunchFlow>
          </LobbySettings>
        </>
      )}
    </Wrapper>
  );
};

export default MoreMenu;
