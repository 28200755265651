import React, { FC } from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { store, useStore } from "../../../core/store";
import { modalClear } from "../Modal/Modals";
import { Forward, Back, LockIcon } from "../../../assets/icons/Icons";
import { secondsToTimeString } from "../Badge/LobbyStatusBar";
import { FlowItemGroupSize } from "./FlowItemGroupSize";

export const ButtonContainer = styled.button`
  background-color: #ee6e5e;
  font-weight: bold;
  height: calc(100% + 1px);
  width: 45px;
  position: absolute;
  color: #fff;
  right: 0;
  top: 0;
  border: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid #cc4e2e;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;

  box-sizing: border-box;

  &.back {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 0px;
    right: auto;
  }

  &.locked {
    background-color: #fe8e7e;

    &:hover {
      background-color: #ff8f7f !important;
    }
  }
`;

const Flow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 9px 10px 17px;
  background-color: #fdf1e4;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #ddd1b4;

  &:hover {
    ${ButtonContainer} {
      background-color: #de5e4e;
      border-bottom: 1px solid #ae2e1e;
    }
  }

  &.back {
    padding-left: 60px;
  }

  &.forward {
    padding-right: 60px;
  }
`;

const FlowInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShortFlowDescription = styled.div`
  font-size: 12px;
  line-height: 24px;
  height: 19px;
  overflow: hidden;
  position: relative;
  color: gray;
  p {
    margin: 0;
  }
`;

const MoreDescription = styled.div`
  position: absolute;
  font-size: 12px;
  right: 0;
  top: 0;
  background-color: #fdf1e4;

  .more {
    text-decoration: underline;
  }

  &:hover {
    color: #999;
  }
`;

interface Props {
  flow: LobbyInvitation;
  mode: "forward" | "back";
}

export function getFlowTimeString(flow: LobbyInvitation) {
  if (!flow.minDuration || !flow.maxDuration) {
    return secondsToTimeString(flow.duration);
  } else if (flow.minDuration != flow.maxDuration) {
    const minDurationString = secondsToTimeString(flow.minDuration);
    const maxDurationString = secondsToTimeString(flow.maxDuration);
    return `${minDurationString} - ${maxDurationString}`;
  } else {
    return secondsToTimeString(flow.minDuration);
  }
}

export const FlowItem: FC<Props> = ({ flow, mode }) => {
  const intl = useIntl();

  const recentHeat = store.use.recentHeat()[flow.heatType];

  return (
    <Flow
      key={flow.hashId}
      className={mode}
      data-testid={`flow-${flow.hashId}`}
      onClick={() => {
        if (mode === "forward")
          useStore.setState({
            selectedFlow: flow,
            selectedRoom: undefined,
            currentFlowConfig: Object.assign({}, flow),
          });
        if (mode === "back")
          useStore.setState({ selectedFlow: undefined, selectedRoom: undefined, currentFlowConfig: undefined });
        modalClear("RoomsConfig");
      }}
    >
      {mode === "back" && (
        <ButtonContainer
          className="back"
          aria-label={intl.formatMessage({
            description: "Back to Flow List",
            id: "flow_item.back",
            defaultMessage: "Back to Flow List",
          })}
          title={`Back to Flow List`}
        >
          <Back fill={"#fff"} />
        </ButtonContainer>
      )}
      <FlowInfo>
        <div
          style={{
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          {flow.name}
        </div>
        {flow.description && (
          <>
            <ShortFlowDescription>
              <div
                dangerouslySetInnerHTML={{
                  __html: flow.description,
                }}
              />
              <MoreDescription
                onClick={(event) => {
                  store.setState({
                    showFlowDescriptionModal: flow.hashId,
                  });
                  event.stopPropagation();
                }}
              >
                ...
                <span className={"more"}>
                  <FormattedMessage id="flows_list.more_description_button" defaultMessage="more" />
                </span>
              </MoreDescription>
            </ShortFlowDescription>
          </>
        )}
        <FlowItemGroupSize flow={flow} />
      </FlowInfo>
      {mode === "forward" && flow.minHeat < recentHeat && (
        <ButtonContainer
          aria-label={intl.formatMessage(
            {
              description: "Select {flowName}",
              id: "flow_item.select",
              defaultMessage: "Select {flowName}",
            },
            { flowName: flow.name },
          )}
          title={`Select ${flow.name}`}
        >
          <Forward fill={"#fff"} />
        </ButtonContainer>
      )}
      {mode === "forward" && flow.minHeat > recentHeat && (
        <ButtonContainer
          className="locked"
          aria-label={intl.formatMessage(
            {
              description: "Select {flowName}",
              id: "flow_item.select",
              defaultMessage: "Select {flowName}",
            },
            { flowName: flow.name },
          )}
          title={`Select ${flow.name}`}
        >
          <LockIcon stroke={"#fff"} />
        </ButtonContainer>
      )}
    </Flow>
  );
};
