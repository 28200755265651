export interface TimeEstimateRequest {
  lobbyHashId: string;
  flowHashId: string;
  numParticipants: number;
  min: number;
  max: number;
  pref: number | undefined;
}
export interface TimeEstimateResponse {
  minDuration: number;
  maxDuration: number;
  minGroupSize: number;
  maxGroupSize: number;
  numUnmatchedParticipants: number;
  numGroups: number;
}

export default async (timeEstimateRequest: TimeEstimateRequest): Promise<TimeEstimateResponse> => {
  return fetch(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/time-estimate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(timeEstimateRequest),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      return {
        heading: "Sorry, something went wrong",
        message: "Please try again or reload the app",
      };
    });
};
