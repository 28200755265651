import React from "react";

import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store } from "../../../core/store";
import { ClosedCaption } from "../../../assets/icons/Icons";

const ClosedCaptionsButton = () => {
  const closedCaptionsEnabled = store.use.closedCaptionsEnabled();

  return (
    <>
      <MenuButton
        buttonIcon={<ClosedCaption />}
        onClick={() => store.setState({ closedCaptionsEnabled: !closedCaptionsEnabled })}
      >
        {!closedCaptionsEnabled ? (
          <FormattedMessage id="closed_captions_button.enable" defaultMessage="Show CC" />
        ) : (
          <FormattedMessage id="closed_captions_button.disable" defaultMessage="Hide CC" />
        )}
      </MenuButton>
    </>
  );
};

export default ClosedCaptionsButton;
