import React, { FC } from "react";
import { ScreenshareTile } from "./ScreenshareTile";
import { VideoGridTile } from "./VideoGridTile";
import { PackedGrid } from "react-packed-grid";
import { PaginationControls } from "./PaginationControls";
import { ScreenShare } from "@daily-co/daily-react";
import { PARTICIPANTS_PER_PAGE } from "../../../lib/defaults";
import { ExternalContentTile } from "./ExternalContentTile";
import { AnalyticsWidget } from "../AnalyticsWidget/AnalyticsWidget";
import { store } from "../../../core/store";

interface VideoGridDesktopProps {
  filteredParticipants: Participant[];
  screens: ScreenShare[];
  showSelf: boolean;
  currentUser: UserResponse;
  roleData: RoleData;
  page: number;
  setPage: (page: number) => void;
}

export const VideoGridDesktop: FC<VideoGridDesktopProps> = ({ filteredParticipants, screens, page, setPage }) => {
  const pages = Math.ceil(filteredParticipants.length / PARTICIPANTS_PER_PAGE);
  const invitation = store.use.invitation();
  const showExternalContent = store.use.showExternalContent();

  return (
    <>
      {screens.length > 0 && (
        <div style={{ width: "80%", flexShrink: 0, height: "100%" }}>{<ScreenshareTile screen={screens[0]} />}</div>
      )}
      {showExternalContent && (invitation?.testEnabled ? <AnalyticsWidget /> : <ExternalContentTile />)}

      <div
        style={{
          height: "calc(100% - 60px)",
          width: "100%",
          alignSelf: "top",
          justifySelf: "flex-start",
        }}
      >
        <PackedGrid boxAspectRatio={0.9} className="fullscreen">
          {filteredParticipants.slice(page * PARTICIPANTS_PER_PAGE, (page + 1) * PARTICIPANTS_PER_PAGE).map((p) => {
            return (
              <VideoGridTile
                dailySessionID={p.dailySessionID}
                userName={p.name}
                warmspaceID={p.id}
                participantLocation={p.location}
                key={`video-${p.id}`}
              />
            );
          })}
        </PackedGrid>

        {filteredParticipants.length > PARTICIPANTS_PER_PAGE && (
          <PaginationControls>
            {Array.from(Array(pages).keys()).map((i) => {
              return (
                <div
                  className={`pageIndicator ${page === i ? "active" : ""}`}
                  key={`page-${i}`}
                  onClick={() => setPage(i)}
                ></div>
              );
            })}
          </PaginationControls>
        )}
      </div>
    </>
  );
};
