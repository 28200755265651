import React, { FC, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { Button, LoadingButton } from "../ButtonV2/Button";
import { Close } from "../../../assets/icons/Icons";

import { Overlay, Card, CardBody, CardHeader } from "../Modal/ModalStyle";
import { store, useStore } from "../../../core/store";
import { modalClearAll } from "../Modal/Modals";
import { renameParticipant } from "../../../core/SessionChannel/renameParticipant";
import { TextInput } from "grommet";
import styled from "styled-components";

const InputWrapper = styled.span`
  margin: 20px 0;
  width: 100%;
  display: flex;
  .text-input {
    width: 230px;
  }
  button {
    width: 130px;
  }
`;

interface Props {
  participantId: number;
}
export const RenameParticipantModal: FC<Props> = ({ participantId }) => {
  const currentUser = store.use.currentUser();
  const participants = store.use.allParticipants();
  const participant = participants[participantId];
  const [currentName, setCurrentName] = useState<string>(participant.name);
  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);

  function clearModal() {
    useStore.setState({ showRenameParticipantModal: -1 });
  }

  function handleSubmit() {
    if (currentName.length > 0) {
      setButtonLoading(true);
      renameParticipant(participantId, currentName, clearModal);
    }
  }

  useEffect(() => {
    setTimeout(modalClearAll, 150);
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <FormattedMessage id="rename_participant_modal.header" defaultMessage={"Edit User Name"} />
          <Button className="link" onClick={clearModal}>
            <Close />
          </Button>
        </CardHeader>
        <CardBody>
          {currentUser.id == participant.id ? (
            <FormattedMessage
              id="rename_participant_modal.rename_yourself_message"
              defaultMessage="Change your preferred name for this and future sessions"
            />
          ) : (
            <FormattedMessage
              id="rename_participant_modal.rename_participant_message"
              defaultMessage="Change {userName}'s preferred name for this session and future sessions"
              values={{ userName: participant.name }}
            />
          )}
          <InputWrapper>
            <TextInput
              className="text-input"
              value={currentName}
              placeholder={"new name for " + participant.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentName(e.target.value)}
            />
            <LoadingButton onClick={handleSubmit} loading={buttonLoading == true} disabled={currentName.length == 0}>
              <FormattedMessage id="rename_participant_modal.update_button" defaultMessage="Update" />
            </LoadingButton>
          </InputWrapper>
        </CardBody>
      </Card>
      <Overlay onClick={clearModal} blur={false} />
    </>
  );
};

export default RenameParticipantModal;
