import React, { useCallback, useEffect, useState } from "react";
import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import { WordCloudWord } from "./AnalyticsWidget";
import styled from "styled-components";

const WordcloudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  svg {
    margin: 1rem 0;
    cursor: pointer;
  }

  label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-right: 8px;
  }
  textarea {
    min-height: 100px;
  }

  @keyframes leaves {
    0% {
      opacity: 0;
      top: -10px;
      font-size: 10px;
    }
    100% {
      opacity: 1;
      top: 0;
      //font-size: auto;
    }
  }

  text {
    position: relative;
    animation: leaves 0.3s ease-in;
  }
`;

interface ExampleProps {
  width: number;
  height: number;
  showControls?: boolean;
  words: WordCloudWord[];
}

export interface WordData {
  text: string;
  value: number;
}

const colors = ["#ff622e", "#ff8d24", "#eee", "#fee16b", "#feb401"];

function getRotationDegree() {
  const rand = Math.random();
  const degree = rand > 0.5 ? 60 : -60;
  return rand * degree;
}

const fixedValueGenerator = () => 0.5;

type SpiralType = "archimedean" | "rectangular";

export default function WordCloud({ width, height, showControls, words }: ExampleProps) {
  const [spiralType, setSpiralType] = useState<SpiralType>("archimedean");
  const [withRotation, setWithRotation] = useState(false);

  const fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.count)), Math.max(...words.map((w) => w.count))],
    range: [25, 100],
  });
  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  return (
    <WordcloudWrapper>
      <Wordcloud
        words={words.map((w) => ({ text: w.text, value: w.count }))}
        width={width}
        height={height}
        fontSize={fontSizeSetter}
        font={"ITC Avant Garde Gothic"}
        padding={2}
        spiral={spiralType}
        rotate={withRotation ? getRotationDegree : 0}
        random={fixedValueGenerator}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={"middle"}
              transform={`translate(${w.x}, ${w.y})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
      {showControls && (
        <div>
          <label>
            Spiral type &nbsp;
            <select onChange={(e) => setSpiralType(e.target.value as SpiralType)} value={spiralType}>
              <option key={"archimedean"} value={"archimedean"}>
                archimedean
              </option>
              <option key={"rectangular"} value={"rectangular"}>
                rectangular
              </option>
            </select>
          </label>
          <label>
            With rotation &nbsp;
            <input type="checkbox" checked={withRotation} onChange={() => setWithRotation(!withRotation)} />
          </label>
          <br />
        </div>
      )}
    </WordcloudWrapper>
  );
}
