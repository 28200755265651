import styled from "styled-components";

export const Well = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
  overflow-y: auto;
  flex-shrink: 0;
`;
