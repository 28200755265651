import React, { useState, useRef, useEffect } from "react";
import { Chat } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store, useStore } from "../../../core/store";
import styled from "styled-components";
import { modalClearAll } from "../Modal/Modals";
import { SFX2 } from "../../../assets/SFX2";

export const NotificationDot = styled.div`
  height: 10px;
  width: 10px;
  background-color: #f00;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -31px;
  left: -6px;
  animation: bounce 0.3s infinite alternate;

  @keyframes bounce {
    from {
      transform: translateY(2px);
    }

    to {
      transform: translateY(-1px);
    }
  }

  @media (max-width: 736px) {
  }
`;

export const ChatButton = () => {
  const [playedSound, setPlayedSound] = useState(false);
  const isOpen = useStore((state) => state.showChat);

  function usePrevious(value: number) {
    const ref = useRef<number>(0);
    useEffect(() => {
      ref.current = value; //assign the value of ref to the argument
    }, [value]); //this code will run when the value of 'value' changes
    return ref.current; //in the end, return the current ref value.
  }

  const currentUser = store.use.currentUser();
  const messages = store.use.chatMessages();
  const showChat = store.use.showChat();

  const previousMessageCount = usePrevious(messages.length);
  const currentMessageCount = messages.length;

  useEffect(() => {
    setPlayedSound(false);

    if (isOpen) {
      const newChatMessages = messages.map((x) => ({ ...x, read: true }));
      useStore.setState({ chatMessages: newChatMessages });
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      previousMessageCount !== currentMessageCount &&
      currentMessageCount !== 0 &&
      !isOpen &&
      !playedSound &&
      messages.some((x) => !x.read && x.user_id !== currentUser.id)
    ) {
      SFX2.playAudio(SFX2.newChatMessage, 0.06);
      setPlayedSound(true);
    }
  }, [previousMessageCount, currentMessageCount, messages, isOpen, playedSound]);

  return (
    <MenuButton
      buttonIcon={<Chat fill={showChat ? "#FFA100" : "white"} />}
      onClick={() => {
        useStore.setState({ showChat: !showChat });
        modalClearAll();
      }}
      className={showChat ? "selected" : ""}
    >
      <FormattedMessage description="Chat button text" id="chat_button" defaultMessage={"Chat"} />
      {playedSound && <NotificationDot />}
    </MenuButton>
  );
};
