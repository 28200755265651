import React, { FC } from "react";

import { CameraAreaWrapper, Camera, CameraBox, CameraState, Toolbox } from "./styles";

import AudioLevel from "../UI/AudioLevel/AudioLevel";
import { ElementPosition } from "../UI/AudioLevel/AudioLevelInner";
import { FormattedMessage } from "react-intl";
import { ExtendedDailyParticipant, StatefulDevice } from "@daily-co/daily-react";
import { MediaTrackState } from "@daily-co/daily-react/dist/hooks/useMediaTrack";
import { CameraButton } from "../UI/Menu/CameraButton";
import { MicButton } from "../UI/Menu/MicButton";
import { MoreButton } from "../UI/Menu/MoreButton";
import { can } from "../../helpers/can";
import { store } from "../../core/store";

interface CameraAreaProps {
  cameraState: {
    state: string;
    message: React.JSX.Element;
  };
  selectedCamera?: StatefulDevice;
  cameraErrorMessage: string | false;
  cameraStarted: () => void;
  videoTrack: MediaTrackState;
  localParticipant: ExtendedDailyParticipant | null;
  videoElement: React.RefObject<HTMLVideoElement | null>;
}

export const CameraArea: FC<CameraAreaProps> = ({
  cameraState,
  selectedCamera,
  cameraErrorMessage,
  cameraStarted,
  videoTrack,
  localParticipant,
  videoElement,
}) => {
  console.debug("[session-ui]: CameraArea render");

  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();

  return (
    <CameraAreaWrapper>
      <CameraBox>
        <Camera data-testid="camera-area">
          {cameraState.state !== "camera_started" && (
            <CameraState data-testid="camera-state">
              {cameraState.message}
              {cameraState.state === "camera_error" ? (
                <div style={{ padding: "0px 10px" }}>
                  <small style={{ lineHeight: "1.6em" }}>
                    <FormattedMessage
                      description="Camera Area video permissions error"
                      id="camera_area.permission_camera_error"
                      defaultMessage="Please check if your browser has permission."
                    />
                    <br />
                    <FormattedMessage
                      description="Camera Area video refreshing error"
                      id="camera_area.refresh_camera_error"
                      defaultMessage="And try refreshing or restarting your browser."
                    />
                  </small>
                  <br />
                  <small style={{ color: "#ccc", fontStyle: "italic" }}>
                    <FormattedMessage
                      description="Camera Area error message"
                      id="camera_area.error_message"
                      defaultMessage='Error message: "{error}"'
                      values={{ error: cameraErrorMessage }}
                    />
                  </small>
                </div>
              ) : (
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                <small> {selectedCamera?.device.label || " . . . "}</small>
              )}
            </CameraState>
          )}

          {videoTrack?.persistentTrack && (
            <video
              muted
              style={{ objectFit: "cover", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              width="100%"
              height="100%"
              autoPlay
              playsInline
              disablePictureInPicture={true}
              onPlaying={cameraStarted}
              ref={videoElement}
            />
          )}
          {localParticipant?.tracks?.audio?.persistentTrack ? (
            <AudioLevel id={localParticipant?.session_id} local={true} position={ElementPosition.BottomRight} />
          ) : (
            ""
          )}
        </Camera>

        {cameraState.state !== "camera_error" && (
          <Toolbox>
            <CameraButton />
            {can("manageOwnMuteState", currentUser, invitation) && <MicButton />}
            <MoreButton />
          </Toolbox>
        )}
      </CameraBox>
    </CameraAreaWrapper>
  );
};
