import styled from "styled-components";

const Overlay = styled.div<{ blur: boolean }>`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  display: block;
  animation: darken 0.2s forwards;
  backdrop-filter: ${({ blur }) => (blur ? "blur(11px)" : "none")};

  @keyframes darken {
    from {
      background-color: rgba(0, 0, 0, 0);
    }
    to {
      background-color: rgba(
        ${({ blur }) => (blur ? "20" : "0")},
        ${({ blur }) => (blur ? "20" : "0")},
        ${({ blur }) => (blur ? "20" : "0")},
        0.75
      );
    }
  }
`;

const ClearOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  bottom: 65px;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 5;
  width: 100%;
  display: block;
`;

const Card = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background: white;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  width: calc(100% - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  gap: 5px;
  box-sizing: border-box;
  max-width: 400px;
  height: auto;
  overflow-y: auto;
  z-index: 110;
  color: black;
  padding-top: 10px;
`;

const CardFullScreen = styled.div`
  position: fixed;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: #ffeecc;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  gap: 10px;
  overflow: hidden;
  z-index: 110;
  color: black;
  padding: 10px 0px 13px 0px;
`;

const NarrowCard = styled.div`
  position: fixed;
  border-radius: 10px;

  z-index: 11;
  color: black;

  background: #ffeecc;

  top: 42px;
  bottom: 69px;
  left: 5px;
  right: 5px;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media (min-width: 450px) {
    width: 400px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 0px 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;

  line-height: normal;

  &.fullscreen {
    padding: 10px 15px;
    box-sizing: border-box;
  }
`;

const CardFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f2f2f2;
  box-sizing: border-box;
  height: 64px;
  padding: 0px 14px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  color: black;
`;

const CardBody = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 14px;
  flex-direction: column;

  gap: 5px;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;

  &.fullscreen {
    position: relative;
    height: calc(100% - 52px);
  }
`;

const TextHeader = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: center;
`;

const ConfigHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 5px;
  color: #000;
  box-sizing: border-box;

  svg {
    width: 20px;
  }

  border-top: 1px solid #ddd;
  padding-top: 20px;
`;

const CheckBox = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  webkit-appearance: none;
  appearance: none;
  border: 2px solid #ff5f25;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:checked {
    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 12px;
      background: #ff5f25;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      margin-left: 3px;
      margin-right: 3px;
    }
  }
`;

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #d3d3d3;
  pointer-events: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #ff5f25;
    border-radius: 50%;
    cursor: pointer;
  }

  &::moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    width: 24px;
    height: 24px;
    background-color: #ff5f25;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-webkit-slider-thumb:hover {
    background-color: #ff7729;
  }

  &.fromSlider {
    height: 0;
    z-index: 1;
  }

  &.toSlider {
    height: 4px;
    top: -2px;
    z-index: 0;
  }
`;

const InfoText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #999;
  margin-bottom: 10px;
`;

const Tracker = styled.div<{ selected?: boolean }>`
  line-height: normal;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${({ selected }) => (selected ? "#ff5f25" : "#f2f2f2")};
  color: ${({ selected }) => (selected ? "#fff" : "#000")};
  cursor: pointer;
`;

export {
  Overlay,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  ClearOverlay,
  CardFullScreen,
  InfoText,
  NarrowCard,
  TextHeader,
  ConfigHeader,
  CheckBox,
  Slider,
  Tracker,
};
