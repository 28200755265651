import React from "react";
import { Video, VideoSlash } from "../../../assets/icons/Icons";
import MenuButton from "./MenuButton";
import { useIntl } from "react-intl";
import { useDaily, useLocalParticipant, useVideoTrack } from "@daily-co/daily-react";
import toast from "react-simple-toasts";
import { useStore } from "../../../core/store";

export const CameraButton = () => {
  const localParticipant = useLocalParticipant();
  const localVideo = useVideoTrack(localParticipant?.session_id || "");
  const callObject = useDaily();
  const cameraOn = callObject?.localVideo();

  const intl = useIntl();

  function toggleVideoCamera() {
    if (localVideo.state === "blocked") {
      toast(`Could not start camera. Please reload the page and accept camera permissions.`, {
        duration: 1500,
      });
    }

    toast(`${cameraOn ? "Camera disabled" : "Enabling camera"}`, {
      duration: 1500,
      loading: !cameraOn,
    });

    if (!cameraOn) {
      useStore.setState({ showSelf: true });
    }

    callObject?.setLocalVideo(!callObject?.localVideo());
  }

  return (
    <MenuButton
      buttonIcon={cameraOn ? <Video fill="white" width={21} /> : <VideoSlash fill="white" width={"21px"} />}
      onClick={toggleVideoCamera}
    >
      {cameraOn
        ? intl.formatMessage({
            description: "Camera Button Menu button text to turn off camera",
            id: "camera_button.camera_off",
            defaultMessage: "Turn off",
          })
        : intl.formatMessage({
            description: "Camera Button Menu button text to turn on camera",
            id: "camera_button.camera_on",
            defaultMessage: "Turn on",
          })}
    </MenuButton>
  );
};
