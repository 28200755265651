import React from "react";
import { Widget } from "./AnalyticsWidget";
import styled from "styled-components";

const EnergyBars = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
`;

const EnergyBarWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: end;

  span {
    font-size: 12px;
    font-weight: normal;
    flex-shrink: 0;
    flex: 0;
  }

  .bar {
    width: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;

    span {
      margin-bottom: 6px;
      font-size: 15px;
      color: #ff8d24;
    }
  }
`;

const BarFill = styled.div`
  width: 100%;
  background: linear-gradient(#fee16b, #feb401);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: height 1s;
  transition-delay: 0.8s;
  flex-shrink: 0;

  &#energyAfter {
    background: linear-gradient(#ff963a, #ff6e2b);
  }
`;

export function BarsWidget({ label, before, after }: { label: string; before: number; after: number }) {
  return (
    <Widget style={{ flex: 2.5 }}>
      <EnergyBars>
        <EnergyBarWrapper id="energyBefore">
          <div className="bar">
            <span>{before.toFixed(1)}</span>
            <BarFill style={{ height: `calc(${(before / 10) * 100}% - 25px)` }}></BarFill>
          </div>
          <span>Before</span>
        </EnergyBarWrapper>
        <EnergyBarWrapper>
          <div className="bar">
            <span>{after.toFixed(1)}</span>
            <BarFill id="energyAfter" style={{ height: `calc(${(after / 10) * 100}% - 25px)` }}></BarFill>
          </div>
          <span>After</span>
        </EnergyBarWrapper>
      </EnergyBars>

      {/*<span>{label}</span>*/}
    </Widget>
  );
}
