import React, { FC } from "react";
import { Card, CardBody, CardHeader, Overlay } from "../Modal/ModalStyle";
import { store } from "../../../core/store";
import { Button } from "../ButtonV2/Button";
import { Close } from "../../../assets/icons/Icons";
import styled from "styled-components";
import { FlowItemGroupSize } from "./FlowItemGroupSize";

interface Props {
  flowHashId: string;
}

const FlowItemDescriptionWrapper = styled.div`
  p {
    margin: 0;
  }

  .card {
    padding: 20px 15px;
  }
  .card-header {
    font-weight: bold;
    font-size: 20px;
    color: #ee6e5e;
  }

  .group-size {
    margin-bottom: 15px;
  }

  .card-body {
    text-align: justify;
  }
`;

export const FlowItemDescriptionModal: FC<Props> = ({ flowHashId }) => {
  const flows = store.use.invitation().lobbyInvitations;
  const flow = flows.find((flow) => flow.hashId === flowHashId)!;

  function clearFlowDescriptionModal() {
    store.setState({ showFlowDescriptionModal: "" });
  }

  return (
    <>
      <FlowItemDescriptionWrapper>
        <Card className="card">
          <CardHeader className="card-header">
            {flow.name}
            <Button className="link" onClick={clearFlowDescriptionModal}>
              <Close />
            </Button>
          </CardHeader>
          <CardBody className="card-body">
            <FlowItemGroupSize flow={flow} />
            <div
              dangerouslySetInnerHTML={{
                __html: flow.description || "n/a",
              }}
            />
          </CardBody>
        </Card>
      </FlowItemDescriptionWrapper>
      <Overlay onClick={clearFlowDescriptionModal} blur={false} />
    </>
  );
};
