import React from "react";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { isInRoom, OfflineSign, ParticipantItem, StyledRoom } from "./RoomsPage";
import { Back, ClockIcon, Close, More, Participants } from "../../../assets/icons/Icons";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { textForLocale } from "../../../lib/textForLocale";
import { useStore } from "../../../core/store";

import { modalClear, modalShow } from "../Modal/Modals";
import { Overlay } from "../Modal/ModalStyle";
import useCountDown from "../../../hooks/useCountDown";
import { DoneIndicator } from "../../InPersonSession/InPersonSession";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  padding-bottom: 0px;

  background-color: #fff;

  border-top-left-radius: 9px;
  border-top-right-radius: 9px;

  position: relative;
`;

const BackButtonContainer = styled.button`
  background-color: #ee6e5e;
  font-weight: bold;
  height: calc(100% + 1px);
  width: 45px;

  border: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: 1px solid #cc4e2e;
`;

const StyledHeaderBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ffa632;
  margin: auto;
  z-index: 20;
  margin-bottom: 0px;
  margin-top: 4px;
`;

const Widgets = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
`;

const Widget = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px;
  line-height: 15px;
  border-radius: 5px;
  font-size: 16px;
`;

const Title = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 16px;

  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  i {
    font-weight: normal;
  }

  gap: 3px;

  svg {
    position: relative;
    top: -1px;
    width: 22px;
    height: 22px;
  }
`;

const DropdownButton = styled.button`
  position: absolute;
  height: 100%;
  background-color: #fff;

  right: 0px;
  top: 0px;
  width: 50px;
  border-top-right-radius: 9px;
  border: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #ddd;
  }

  &.selected {
    z-index: 115;
  }
`;

const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  flex-direction: column;

  z-index: 110;
  position: absolute;
  top: 100%;
  right: 0px;
  display: none;

  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;

  border-bottom: 1px solid #ccc;

  &.visible {
    display: flex;
  }
`;

const Body = styled.div`
  width: 100%;
`;

const DropdownMenuItem = styled.button`
  font-size: 16px;
  padding: 10px 15px;

  text-align: left;
  border: none;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #eee;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;

export function formatSecondsToString(seconds: number) {
  const secondPortion = seconds % 60;
  const secondsString = secondPortion <= 9 ? `0${secondPortion}` : secondPortion;
  return `${Math.trunc(seconds / 60)}:${secondsString}`;
}

function isOnline(participant: Participant) {
  return participant.location !== "offline" && participant.location !== "dropped";
}

// Closes a room or all rooms depending on roomHashId.
// If roomHashId is null, all rooms are closed.
const handleCloseRoom = (roomHashId: string | null) => {
  return async () => {
    useStore.setState({
      selectedRoom: roomHashId || "allRooms",
    });
    modalShow("ConfirmCloseBreakout", false);
  };
};

export const Room = ({
  room,
  idx,
  locale,
  getRoomParticipants,
  allParticipants,
  showBackNavigation,
}: {
  room: BreakoutRoomMessage;
  idx: number;
  locale: string;
  getRoomParticipants: (room: BreakoutRoomMessage, allParticipants: Participants) => Participant[];
  allParticipants: Participants;
  showBackNavigation: boolean;
}) => {
  const intl = useIntl();

  const [openRoomContextMenu, setOpenRoomContextMenu] = React.useState(false);

  const currentStep = room.stepIndex + 1 || 1;
  const totalSteps = room.totalSteps || undefined;
  const name = textForLocale(room.sectionName, locale) || "";

  const expirationDate = room.roomClosingTime ? new Date(room.roomClosingTime + "+00:00") : undefined;
  const secondsRemaining = useCountDown(expirationDate);

  return (
    <StyledRoom key={room.hashId} data-testid={`room-${room.hashId}`}>
      {showBackNavigation && (
        <BackButtonContainer
          aria-label={intl.formatMessage({
            id: "breakout_rooms.back_to_rooms_list",
            defaultMessage: "Back to rooms list",
          })}
          title={`Back to rooms list`}
          onClick={() => {
            modalClear("AddParticipantToRoom");
          }}
        >
          <Back fill={"#fff"} />
        </BackButtonContainer>
      )}
      <Body>
        <StyledHeader className="breakout-modal room">
          {(room.status === "closed" || room.status === "completed") && (
            <Title>
              {room.roomName} {idx + 1}
              &nbsp;
              <i>
                <FormattedMessage
                  id="breakout_rooms.status"
                  defaultMessage="({roomStatus})"
                  values={{ roomStatus: room.status }}
                />
              </i>
            </Title>
          )}
          {(room.status === "ongoing" || room.status === "new" || room.status === "ready") && (
            <Widgets>
              <Widget>
                <b>
                  <FormattedMessage
                    id="breakout_rooms.info"
                    defaultMessage="{currentStep} / {totalSteps}"
                    values={{ currentStep: currentStep, totalSteps: totalSteps }}
                  />
                </b>
                {name}
              </Widget>

              <Widget>
                <Participants
                  stroke="#000"
                  style={{
                    marginRight: "-5px",
                  }}
                />
                {room.participantIds.length}
              </Widget>
              {secondsRemaining > 0 && (
                <Widget>
                  <ClockIcon stroke="#000" />

                  {formatSecondsToString(secondsRemaining)}
                </Widget>
              )}
            </Widgets>
          )}

          {(room.status === "ongoing" || room.status === "new" || room.status === "ready") && !showBackNavigation && (
            <DropdownButton
              className={openRoomContextMenu ? "selected" : ""}
              onClick={() => {
                if (openRoomContextMenu) setOpenRoomContextMenu(false);
                else setOpenRoomContextMenu(true);
              }}
            >
              {openRoomContextMenu && <Close stroke="#000" />}
              {!openRoomContextMenu && <More fill="#000" />}
            </DropdownButton>
          )}

          <DropdownMenu className={openRoomContextMenu ? "visible" : ""}>
            <DropdownMenuItem onClick={handleCloseRoom(room.hashId)}>
              <FormattedMessage id="breakout_rooms.closeRoom" defaultMessage="Close room" />
            </DropdownMenuItem>

            <DropdownMenuItem
              onClick={() => {
                useStore.setState({
                  selectedRoom: room.hashId,
                });
                setOpenRoomContextMenu(false);
                modalShow("AddParticipantToRoom", false);
              }}
            >
              <FormattedMessage id="breakout_rooms.addParticipant" defaultMessage="Add participant" />
            </DropdownMenuItem>
          </DropdownMenu>

          <StyledHeaderBorder />
        </StyledHeader>

        <ParticipantsWrapper>
          {getRoomParticipants(room, allParticipants).map((participant) => {
            const actionStatus = room.roleData[participant.id]?.actionStatus;
            const classNames = [];
            if (!isInRoom(participant, room)) classNames.push("offline");
            if (actionStatus == "PENDING") classNames.push("has-action");
            return (
              <ParticipantItem className={classNames.join(" ")} key={participant.id}>
                {isOnline(participant) ? null : <OfflineSign />}
                {participant.name}
                {actionStatus == "COMPLETED" && <DoneIndicator />}
              </ParticipantItem>
            );
          })}
        </ParticipantsWrapper>
        {openRoomContextMenu && <Overlay onClick={() => setOpenRoomContextMenu(false)} blur={false} />}
      </Body>
    </StyledRoom>
  );
};
