import React, { FC, useEffect } from "react";
import { ScreenshareTile } from "./ScreenshareTile";
import { VideoGridTileContainer, VideoGridTile } from "./VideoGridTile";
import styled from "styled-components";
import { PaginationControls } from "./PaginationControls";
import { ScreenShare } from "@daily-co/daily-react";
import { ExternalContentTile } from "./ExternalContentTile";
import Username from "../Username/Username";
import { store, useStore } from "../../../core/store";
import { AnalyticsWidget } from "../AnalyticsWidget/AnalyticsWidget";

const FeaturedVideo = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  position: relative;

  flex: 1;
  flex-shrink: 0;

  .participantTile {
    video {
      height: calc(100vh - 230px);
    }
  }

  &.participants-1 {
    .participantTile {
      video {
        height: calc(100vh - 70px);
      }
    }
  }
`;

const PaginatedVideos = styled.div`
  height: 180px;
  width: 100%;
  display: flex;

  flex-shrink: 1;

  scroll-snap-type: x mandatory;

  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-x;
  gap: 5px;
  box-sizing: border-box;

  scrollbar-color: #444 #222;

  /* WebKit and Chromiums */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #222;
  }

  &::-webkit-scrollbar-thumb {
    background: #444;
    border-radius: 5px;
  }

  &.participants-1 {
    height: 0px;
    padding: 0px;
  }

  &.screensharing {
    height: 180px;
  }

  &.participants-1 {
    justify-content: center;
  }

  &.participants-4ormore {
    ${VideoGridTileContainer} {
      width: calc(50vw - 7.5px);
    }
  }

  ${VideoGridTileContainer} {
    scroll-snap-align: start;

    width: calc(50vw - 7.5px);
    max-width: 210px;
    flex-shrink: 0;
    max-height: 180px;
    position: relative;

    .scrollGrabber {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
`;

interface VideoGridMobileProps {
  filteredParticipants: Participant[];
  screens: ScreenShare[];
  classes: string[];
  showScreenOrContent: boolean;
}

export const VideoGridMobile: FC<VideoGridMobileProps> = ({
  filteredParticipants,
  screens,
  classes,
  showScreenOrContent,
}) => {
  const roleData = store.use.roleData();
  const currentUser = store.use.currentUser();

  const dailySessionId = currentUser?.dailySessionID || useStore.getState().participants[currentUser.id].dailySessionID;
  const invitation = store.use.invitation();
  const showExternalContent = store.use.showExternalContent();

  // TODO VG: This is a hack. If the dailySessionId is missing for any
  //  reason when we get to this point, then something is very wrong.
  //  This is a simple way to recover.
  useEffect(() => {
    if (!dailySessionId) {
      if (process.env.NODE_ENV !== "production") {
        location.reload();
      } else {
        throw new Error(`Expected to have a dailySessionId but it's currently: ${dailySessionId}`);
      }
    }
  }, [dailySessionId]);

  return (
    <>
      {filteredParticipants.length > 0 && (
        <FeaturedVideo className={classes.join(" ")}>
          {!showScreenOrContent && (
            <VideoGridTile
              dailySessionID={filteredParticipants[0].dailySessionID}
              userName={filteredParticipants[0].name}
              warmspaceID={filteredParticipants[0].id}
              participantLocation={filteredParticipants[0].location}
              key={"video-" + filteredParticipants[0].id}
            />
          )}

          {screens.length > 0 && <ScreenshareTile screen={screens[0]} />}
          {showExternalContent && (invitation?.testEnabled ? <AnalyticsWidget /> : <ExternalContentTile />)}

          {filteredParticipants.length > 1 && (
            <Username
              name={"You"}
              participantLocation={"session"}
              solo={false}
              isLocal={true}
              dailyID={currentUser.dailySessionID || useStore.getState().participants[currentUser.id].dailySessionID} // TODO VG: Fix this. This isn't right. Something must be going wrong somewhere
              roleData={roleData[currentUser.id]}
              userId={currentUser.id}
            />
          )}
        </FeaturedVideo>
      )}

      <PaginatedVideos id="paginated-videos" className={classes.join(" ")}>
        {filteredParticipants.slice(showScreenOrContent ? 0 : 1).map((p) => {
          return (
            <VideoGridTile
              dailySessionID={p.dailySessionID}
              userName={p.name}
              warmspaceID={p.id}
              participantLocation={p.location}
              data-participantid={p.id}
              key={`video-${p.id}`}
            ></VideoGridTile>
          );
        })}
      </PaginatedVideos>
      {filteredParticipants.slice(showScreenOrContent ? 0 : 1).length > 2 && (
        <PaginationControls>
          {filteredParticipants.slice(showScreenOrContent ? 0 : 1).map((p) => {
            return (
              <div
                className="pageIndicator"
                id={"paginationDotFor-" + p.dailySessionID}
                key={"paginationDot-" + p.id}
              ></div>
            );
          })}
        </PaginationControls>
      )}
    </>
  );
};
